import * as yup from "yup";

export const addGiftCardSchema = yup.object({
  pictingGiftCard: yup.string().required("title is required"),
  image: yup
    .mixed()
    .required("image is required.")
    .test("FILE_TYPE", ".jpg , .png, .jpeg only", (value) => {
      if (value && value.length > 0) {
        for (let i = 0; i < value.length; i++) {
          if (
            value[i].type !== "image/png" &&
            value[i].type !== "image/jpg" &&
            value[i].type !== "image/jpeg"
          ) {
            return false;
          }
        }
      }
      return true;
    }),
  denomination: yup.array().of(
    yup.object().shape({
      denomination: yup.string().ensure().required("denomination is required"),
    })
  ),
  status: yup.string().required("status is required"),
});

export const addProductSchema = yup.object({
  title: yup.string().required("title is required"),
  image: yup
    .mixed()
    .required("image is required.")
    .test("type", ".jpg , .png, .jpeg only", (value) => {
      if (value && value.length > 0) {
        for (let i = 0; i < value.length; i++) {
          if (
            value[i].type !== "image/png" &&
            value[i].type !== "image/jpg" &&
            value[i].type !== "image/jpeg"
          ) {
            return false;
          }
        }
      }
      return true;
    }),
  price: yup.string().required("price is required"),
  compareAt: yup.string().required("compare at is required"),
  chargeTax: yup.boolean(),
  costPerItem: yup.string().when("chargeTax", {
    is: true,
    then: yup.string().required("compare at is required"),
  }),
  barcode: yup.string().required("barcode is required"),

  trackQuantity: yup.boolean(),
  location: yup.string().when("trackQuantity", {
    is: true,
    then: yup.string().required("location at is required"),
  }),
  available: yup.string().when("trackQuantity", {
    is: true,
    then: yup.string().required("available is required"),
  }),

  shippingCheckbox: yup.boolean(),
  weight: yup.string().when("shippingCheckbox", {
    is: true,
    then: yup.string().required("weight is required"),
  }),

  optionCheckbox: yup.boolean(),

  options: yup.array().when("optionCheckbox", {
    is: true,
    then: yup.array().of(
      yup.object().shape({
        name: yup.string().ensure().required("name is required"),
        value: yup.string().ensure().required("name is required"),
      })
    ),
  }),

  status: yup.string().required("status is required"),
  sprice: yup.string().required("price is required"),
  mprice: yup.string().required("quantity is required"),
  squantity: yup.string().required("price is required"),
  mquantity: yup.string().required("quantity is required"),
});
