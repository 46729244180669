import React, { useEffect } from "react";
import "antd/dist/antd.css";
import "../../styles/app.scss";
import { Outlet, useNavigate } from "react-router-dom";
import { Layout } from "antd";
import LayoutHeader from "./LayoutHeader";
import LayoutSidebar from "./LayoutSidebar";
import { useDispatch, useSelector } from "react-redux";
import { setUser, adminProfile } from "../../redux/authSlice";

const MainLayout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const authUser = JSON.parse(localStorage.getItem("authUser"));

    if (authUser?.token) {
      dispatch(setUser());
      dispatch(adminProfile({ navigate }));
    } else {
      navigate("/login");
    }
  }, [navigate]);

  return (
    <Layout>
      <LayoutHeader />
      <Layout>
        <LayoutSidebar />
        <Layout className='mainContent'>
          <Outlet />
        </Layout>
      </Layout>
    </Layout>
  );
};
export default MainLayout;
