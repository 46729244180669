import { Layout, Select, Upload, message } from "antd";
import React, { useEffect, useState } from "react";
import BreadCrumbs from "../../components/layout/BreadCrumbs";
import { BiLeftArrowAlt } from "react-icons/bi";
import { useNavigate, useParams } from "react-router-dom";
import TextEditor from "../../components/custom/TextEditor";
import { BiDollar } from "react-icons/bi";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { stateToHTML } from "draft-js-export-html";
import { useDispatch, useSelector } from "react-redux";
import { EditorState, ContentState, convertFromHTML } from "draft-js";

import {
  updateGiftCard,
  getSingleGiftCard,
  unSetGiftCard,
} from "../../redux/giftCardSlice";
import { FormError } from "../../components/custom/Custom";
import "./giftcard.scss";
import Loading from "../../components/custom/Loading";

const { Content } = Layout;
const { Option } = Select;
const { Dragger } = Upload;

const UpdateGiftCard = () => {
  const [fileList, setFileList] = useState([]);
  const [editorState, setEditorState] = useState("");
  const [mainImage, setMainImage] = useState();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const store = useSelector((state) => state?.giftCard);
  const giftCard = store?.singleGiftCard;
  const giftCardLoading = store?.gifCardLoading;
  useEffect(() => {
    dispatch(getSingleGiftCard(params?.id));

    return () => {
      dispatch(unSetGiftCard());
    };
  }, [params?.id, dispatch]);

  useEffect(() => {
    if (giftCard) {
      const denominations = [];
      if (giftCard?.denominations !== undefined) {
        for (let denomination of giftCard?.denominations) {
          denominations.push({ denomination });
        }
      }

      reset({
        pictingGiftCard: giftCard?.giftCardTitle,
        status: String(giftCard?.status),
        denomination: denominations,
      });

      if (giftCard?.giftCardDescription !== undefined) {
        setEditorState(
          EditorState.createWithContent(
            ContentState.createFromBlockArray(
              convertFromHTML(giftCard?.giftCardDescription)
            )
          )
        );
      }
      setMainImage(giftCard?.media?.[0]);
    }
  }, [giftCard]);

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      denomination: [{ denomination: "" }],
    },
    mode: "onChange",
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "denomination",
  });

  // image component props
  const props = {
    listType: "picture",
    accept: "image/png, image/jpg, image/jpeg",
    maxCount: 3,

    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },

    beforeUpload: (file) => {
      setFileList([...fileList, file]);
      return false;
    },
    // fileList,
  };

  // form submission
  const onFormSubmit = (data) => {
    const imageFormData = new FormData();
    if (fileList.length >= 1) {
      fileList.map((file) => {
        imageFormData.append("image", file);
      });
    }

    let html =
      editorState === ""
        ? "<p>No Description</p>"
        : stateToHTML(editorState.getCurrentContent());
    const denominations = [];
    data?.denomination.map((item) => {
      denominations.push(item.denomination);
    });
    const formData = {
      giftCardTitle: data?.pictingGiftCard,
      giftCardDescription: html,
      denominations,
      status: data?.status,
    };

    dispatch(
      updateGiftCard({
        giftCardID: giftCard?._id,
        formData,
        imageFormData,
        setFileList,
      })
    );
  };

  // loading component
  if (store?.loading) {
    return <Loading />;
  }

  return (
    <>
      <div className='addGiftCardContainer'>
        <div className='addGiftCard'>
          <div className='pageHeader'>
            <BiLeftArrowAlt
              className='backBtn'
              onClick={() => {
                navigate("/products");
              }}
            />
            <BreadCrumbs title='Update gift card product' />
          </div>
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <Content className='addGiftCardContent'>
              {/* right block */}
              <div className='rightBlock'>
                <div className='giftCardDetail'>
                  <div className='title'>
                    <label htmlFor=''>Title</label>
                    <div className='titleFiled'>
                      <input
                        type='text'
                        placeholder='Pictings Gift Card'
                        {...register("pictingGiftCard", {
                          required: "title is required",
                        })}
                      />
                      <img src='/assets/icons/addressCard.svg' />
                    </div>
                    <FormError message={errors?.pictingGiftCard?.message} />
                  </div>

                  <div className='description'>
                    <label htmlFor=''>Description</label>
                    <TextEditor
                      defaultData={giftCard?.giftCardDescription}
                      editorState={editorState}
                      setEditorState={setEditorState}
                    />
                  </div>
                </div>
                <div className='media'>
                  <h3>Media</h3>

                  <Controller
                    control={control}
                    name='image'
                    render={({ field }) => (
                      <Dragger
                        {...props}
                        onChange={(e) => field.onChange(e.fileList)}>
                        <p className='ant-upload-text'>+ Add file</p>
                      </Dragger>
                    )}
                  />
                </div>

                <div className='denominations'>
                  <h3>Denominations</h3>
                  {fields.map((field, index, fields) => {
                    return (
                      <div key={field.id} className='denominationFiled'>
                        <div style={{ width: "100%" }}>
                          <div className='field'>
                            <BiDollar className='usdIcon' />
                            <input
                              type='number'
                              min='0'
                              placeholder='10.00'
                              {...register(
                                `denomination.${index}.denomination`,
                                {
                                  required: "denomination is required",
                                }
                              )}
                            />
                          </div>
                          <FormError
                            message={
                              errors?.denomination?.[index]?.denomination
                                ?.message
                            }
                          />
                        </div>

                        <img
                          src='/assets/icons/trash.svg'
                          className='trashIcon'
                          onClick={() =>
                            fields.length > 1 ? remove(index) : ""
                          }
                        />
                      </div>
                    );
                  })}

                  <button
                    type='button'
                    onClick={() => {
                      append({
                        denomination: "",
                      });
                    }}>
                    Add denomination
                  </button>
                </div>
              </div>
              {/* left block */}
              <div className='leftBlock'>
                <div className='notes'>
                  <h3>Product status</h3>
                  <Controller
                    control={control}
                    name='status'
                    rules={{ required: "status is required" }}
                    render={({ field }) => (
                      <Select
                        name='status'
                        defaultValue={String(giftCard?.status)}
                        placeholder='Select Status'
                        onChange={(value) => {
                          field.onChange(value);
                        }}
                        suffixIcon={
                          <img src='/assets/icons/selectArrows.svg' />
                        }>
                        <Option value='true'>Active</Option>
                        <Option value='false'>Deactive</Option>
                      </Select>
                    )}
                  />
                  <FormError message={errors?.status?.message} />
                </div>
                <div className='giftCardImage'>
                  <h3>Gift card image</h3>
                  <img src={mainImage} />
                  <div className='gallery'>
                    {giftCard?.media?.map((img, i) => {
                      return (
                        <img
                          key={i}
                          className='galleryItem'
                          src={img}
                          onClick={() => {
                            setMainImage(img);
                          }}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
            </Content>
            <div className='pageFooter'>
              <button type='submit'>
                {giftCardLoading ? "Loading..." : "Update"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default UpdateGiftCard;
