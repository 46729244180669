import React, { useState } from "react";
import { Layout, Modal } from "antd";
import "./discount.scss";
import BreadCrumbs from "../../components/layout/BreadCrumbs";
import { useNavigate } from "react-router-dom";
import { MdArrowForwardIos } from "react-icons/md";
const { Content } = Layout;

const Discount = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <div className='pageHeader'>
        <BreadCrumbs title='Discounts' />
        <div className='pageHeaderAction'>
          <button type='button' className='addbtn' onClick={showModal}>
            Create discount
          </button>

          <Modal
            title='Select discount type'
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            className='createDiscountModal'
            width='735px'
            footer={null}>
            <div
              className='discountSection'
              onClick={() => {
                navigate("/discount/amount-off-product");
              }}>
              <div>
                <h3>Amount off products</h3>
                <p>Product discount</p>
              </div>
              <MdArrowForwardIos className='arrowIcon' />
            </div>
            <div
              className='discountSection'
              onClick={() => {
                navigate("/discount/amount-off-order");
              }}>
              <div>
                <h3>Amount off order</h3>
                <p>Order discount</p>
              </div>
              <MdArrowForwardIos className='arrowIcon' />
            </div>
            <div
              className='discountSection'
              onClick={() => {
                navigate("/discount/buy-x-get-y");
              }}>
              <div>
                <h3>Buy X get Y</h3>
                <p>Product discount</p>
              </div>
              <MdArrowForwardIos className='arrowIcon' />
            </div>
            <div
              className='discountSection'
              onClick={() => {
                navigate("/discount/free-shipping");
              }}>
              <div>
                <h3>Free shipping</h3>
                <p>Shipping discount</p>
              </div>
              <MdArrowForwardIos className='arrowIcon' />
            </div>
            <footer>
              <button
                onClick={() => {
                  handleCancel();
                }}>
                Cancel
              </button>
            </footer>
          </Modal>
        </div>
      </div>
      <Content className='discount'>
        <div className='mainContainer'>
          <div className='box'></div>
          <h3>Manage discounts</h3>
          <p>
            Create discount codes and automatic discounts that apply at
            checkout.
          </p>
          <div className='actionBtns' onClick={showModal}>
            <button type='button'>Create discount</button>
          </div>
        </div>
      </Content>
    </>
  );
};

export default Discount;
