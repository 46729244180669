import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiRequests } from "../services/ApiRequest";
import { toast } from "react-toastify";

//signin
export const signin = createAsyncThunk(
  "auth/signin",
  async ({ data, navigate }, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.signin(data);
      localStorage.setItem("authUser", JSON.stringify(response?.data)); //setting user
      navigate("/");
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.errors?.[0]?.msg, {
        position: "top-right",
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "light",
      });
      console.log(error?.response?.data?.errors?.[0]?.msg);
    }
  }
);

export const adminProfile = createAsyncThunk(
  "auth/adminProfile",
  async ({ navigate }, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.adminProfile();
      if (response?.data?.hasOwnProperty("msg")) {
        localStorage.removeItem("authUser");
        navigate("/login");
      } else {
        return response?.data;
      }
    } catch (error) {
      console.log(error);
    }
  }
);

const initialState = {
  loading: false,
  error: "",
  user: {}, // for user object
  profile: {},
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser(state) {
      state.user = JSON.parse(localStorage.getItem("authUser"));
    },
    logout(state) {
      state.user = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signin.pending, (state) => {
        state.loading = true;
      })
      .addCase(signin.fulfilled, (state, action) => {
        state.user = action.payload;
        state.loading = false;
      })
      .addCase(signin.rejected, (state) => {
        state.user = {};
        state.loading = false;
      })

      .addCase(adminProfile.pending, (state) => {
        state.loading = true;
      })
      .addCase(adminProfile.fulfilled, (state, action) => {
        state.profile = action.payload;
        state.loading = false;
      })
      .addCase(adminProfile.rejected, (state) => {
        state.profile = {};
        state.loading = false;
      });
  },
});

export default authSlice.reducer;
export const { setError, setUser, logout } = authSlice.actions;
