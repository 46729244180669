import React, { useEffect, useState } from "react";
import { Table, Modal } from "antd";
import { IoSearch } from "react-icons/io5";
import { IoMdArrowDropdown } from "react-icons/io";
import { MdOutlineCircle } from "react-icons/md";
import { TbArrowsSort } from "react-icons/tb";
import { AiFillStar } from "react-icons/ai";
import { Link } from "react-router-dom";
import { FiEdit, FiTrash } from "react-icons/fi";
import { ExclamationCircleOutlined } from "@ant-design/icons";

// const data = [
//   {
//     key: 0,
//     draftorder: (
//       <div className='orderDetail'>
//         <Link to='/order/12' className='orderName'>
//           #D1
//         </Link>
//       </div>
//     ),
//     date: "Just now",
//     customer: "Lorenzo Test",
//     status: (
//       <div className='badgeWrapper'>
//         <p className='primaryBadge'>
//           <MdOutlineCircle className='badgeIcon' />
//           <span>Open</span>
//         </p>
//       </div>
//     ),
//     total: "100.00 USD",
//   },
// ];

const Pending = ({ allOrders, loading }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [orders, setOrders] = useState(allOrders);

  useEffect(() => {
    setOrders(allOrders);
  }, [allOrders]);

  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const confirm = (id) => {
    Modal.confirm({
      title: "Are you sure!",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      // async onOk() {
      //   await dispatch(deleteProduct(id));
      // },
      cancelText: "No",
    });
  };

  const columns = [
    {
      title: "Order ID",
      dataIndex: "orderID",
    },
    {
      title: "Date",
      dataIndex: "date",
    },
    {
      title: "Customer",
      dataIndex: "customer",
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Total",
      dataIndex: "total",
    },
  ];

  const data = [];
  orders?.map((order) => {
    data.push({
      key: order?._id,
      orderID: (
        <div className='orderDetail'>
          <Link to={`/order/${order?._id}`} className='orderName'>
            #{order?._id}
          </Link>
        </div>
      ),
      date: order?.createdAt?.split("T")[0],
      customer: order?.customer?.fullName,
      status: (
        <div className='badgeWrapper'>
          <p className='primaryBadge'>
            <MdOutlineCircle className='badgeIcon' />
            <span>{order?.orderStatus}</span>
          </p>
        </div>
      ),
      total: `${Number(order?.total).toFixed(2)} USD`,

      // action: (
      //   <div className='action'>
      //     <Link to={`/products/${product?._id}/edit`}>
      //       <FiEdit
      //         className='actionBtn'
      //         // onClick={() => {
      //         //   Modal.info({
      //         //     title: "This feature is under development",
      //         //     onOk() {},
      //         //   });
      //         // }}
      //       />
      //     </Link>
      //     <FiTrash
      //       className='actionBtn'
      //       onClick={() => confirm(product?._id)}
      //     />
      //   </div>
      // ),
    });
  });

  return (
    <>
      <div className='ordersTableContainer'>
        <div className='ordersTableHeader'>
          <div className='ordersSearch'>
            <IoSearch className='searchIcon' />
            <input
              type='text'
              placeholder='search orders'
              onChange={(e) => {
                setOrders(
                  allOrders.filter((order) =>
                    String(order?._id)
                      .toUpperCase()
                      .includes(String(e.target.value).toUpperCase())
                  )
                );
              }}
            />
          </div>

          {/* <div className='filterBtns'>
            <button type='button' className='statusBtn'>
              <span>Status</span>
              <IoMdArrowDropdown className='starIcon' />
            </button>
            <button type='button' className='moreFilterBtn'>
              <span>More filters</span>
            </button>
          </div>

          <button type='button' className='savedBtn'>
            <AiFillStar className='starIcon' />
            <span>Saved</span>
          </button> */}

          {/* <button type='button' className='sortBtn'>
            <TbArrowsSort className='sortIcon' />
            <span>Sort</span>
          </button> */}
        </div>

        <Table
          className='orderTable'
          rowSelection={rowSelection}
          columns={columns}
          dataSource={data}
          pagination={{
            defaultPageSize: 7,
            showSizeChanger: false,
            hideOnSinglePage: true,
          }}
          loading={loading}
        />
      </div>
    </>
  );
};

export default Pending;
