import { Layout, Select } from "antd";
import React, { useEffect } from "react";
import BreadCrumbs from "../../components/layout/BreadCrumbs";
import { BiLeftArrowAlt } from "react-icons/bi";
import { IoCloseSharp } from "react-icons/io5";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./order.scss";
import { HiOutlineCheckCircle } from "react-icons/hi";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { getSingleOrder, updateOrder } from "../../redux/orderSlice";
import { useSelector, useDispatch } from "react-redux";
import Loading from "../../components/custom/Loading";

const { Content } = Layout;

const Order = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSingleOrder(params.orderId));
  }, []);

  const store = useSelector((state) => state?.order);
  const order = store?.singleOrder;
  const customer = store?.singleOrder?.customer;

  // loading component
  if (store?.loading) {
    return <Loading />;
  }

  return (
    <>
      <div className='orderContainer'>
        <div className='order'>
          <div className='pageHeader'>
            <BiLeftArrowAlt
              className='backBtn'
              onClick={() => {
                navigate("/orders");
              }}
            />
            <div className='breadCrumbContainer'>
              <h2 className='breadCrumb'>{order?._id}</h2>
            </div>
            <p>
              Updated by {order?.customer?.fullName} at{" "}
              {order?.createdAt?.split("T")[1]}
            </p>

            {/* <div className='pageHeaderAction'>
              <button type='button' className='exportbtn'>
                Duplicate
              </button>
              <button type='button' className='exportbtn'>
                Share
              </button>

              <div className='arrowBtn'>
                <FiChevronLeft className='leftIcon' />
                <FiChevronRight className='rightIcon' />
              </div>
            </div> */}
          </div>

          <Content className='orderContent'>
            {/* right block */}
            <div className='rightBlock'>
              <div className='orderProducts'>
                <div className='header'>
                  <h3>Products</h3>
                  {/* <Link to='/'>Add custom item</Link> */}
                </div>

                {/* <div className='search'>
                  <div className='searchProduct'>
                    <div className='searchProductFiled'>
                      <img src='/assets/icons/search.svg' />
                      <input type='text' placeholder='Search products' />
                    </div>
                  </div>

                  <button type='button'>Browse</button>
                </div> */}

                <div className='orders'>
                  <div className='orderHeader'>
                    <h3>Product</h3>
                    <div>
                      <h3>Quantity</h3>
                      <h3>Total</h3>
                    </div>
                  </div>

                  <div className='orderBody'>
                    {order?.orderData?.map((data, i, orders) => {
                      console.log(
                        data?.frameSelected?.variant?.[data?.sizeSelected]
                      );
                      return (
                        <div className='orderDetail' key={data?._id}>
                          <div className='orderItem'>
                            <img src={data?.frameSelected?.frame} />
                            <div className='itemDetail'>
                              <h3>{data?.frameSelected?.frameTitle}</h3>
                              <p>{data?.sizeSelected}</p>
                              <p>Free Shipping</p>
                            </div>
                          </div>

                          <div className='orderQuantity'>
                            <div
                            //  className='qtyField'
                            >
                              <p style={{ fontSize: "1rem" }}>1</p>
                              {/* <input type='number' /> */}
                              {/* <FaPercent className='icon' /> */}
                            </div>
                            <p style={{ fontSize: "1rem" }}>
                              $
                              {(
                                1 *
                                data?.frameSelected?.variant?.[
                                  data?.sizeSelected
                                ]?.price
                              ).toFixed(2)}
                            </p>
                            {/* <IoCloseSharp className='icon' /> */}
                          </div>
                        </div>
                      );
                    })}
                  </div>

                  <div className='orderFooter'>
                    <p>Change status </p>
                    <Select
                      placeholder='update status'
                      defaultValue={order?.orderStatus}
                      optionFilterProp='children'
                      onChange={(v) =>
                        dispatch(
                          updateOrder({
                            orderID: order?._id,
                            data: { orderStatus: v },
                          })
                        )
                      }
                      options={[
                        {
                          value: "COMPLETED",
                          label: "COMPLETED",
                        },
                        {
                          value: "PENDING",
                          label: "PENDING",
                        },
                        {
                          value: "CANCELED",
                          label: "CANCELED",
                        },
                      ]}
                    />
                    {/* {order?.orderStatus === "COMPLETED" ? (
                      <h3>{order?.orderStatus}</h3>
                    ) : (
                      <button className=''>Mark as fulfilled</button>
                    )} */}
                  </div>
                </div>
              </div>

              <div className='payment'>
                <div className='header'>
                  {order?.orderStatus === "COMPLETED" ? (
                    <>
                      <HiOutlineCheckCircle className='paidIcon' />
                      <h3>Paid</h3>
                    </>
                  ) : (
                    <>
                      <AiOutlineCloseCircle className='unPaidIcon' />
                      <h3>Unpaid</h3>
                    </>
                  )}
                </div>
                <div className='body'>
                  <div className='subTotal'>
                    <p>Subtotal</p>
                    <p>{order?.orderData?.length}</p>
                    <p>$ {Number(order?.total).toFixed(2)}</p>
                  </div>
                  {/* <div className='tax'>
                    <p>Tax</p>
                    <p>LV VAT 21% (Included)</p>
                    <p>€48.00</p>
                  </div> */}
                  <div className='total'>
                    <p>Total</p>
                    <p>$ {Number(order?.total).toFixed(2)}</p>
                  </div>
                </div>

                <div className='footer'>
                  <p>Paid by customer</p>
                  <p>
                    ${" "}
                    {order?.orderStatus === "COMPLETED"
                      ? Number(order?.total).toFixed(2)
                      : (0).toFixed(2)}
                  </p>
                </div>
              </div>
            </div>

            {/* left block */}
            <div className='leftBlock'>
              <div className='customer'>
                <div className='header'>
                  <h3>Customer</h3>
                  {/* <IoCloseSharp className='icon' /> */}
                </div>
                <div className='customerName'>
                  <p className='name'>{customer?.fullName}</p>
                  <p>{customer?.orders ? customer?.orders : "No orders"}</p>
                </div>

                <div className='contact'>
                  <div className='contactDetail'>
                    <h3>Contact Information</h3>
                    <p className='email'>{customer?.email}</p>
                    {/* <p>No phone number</p> */}
                  </div>
                  {/* <div className='action'>
                    <Link>Edit</Link>
                    <img src='/assets/icons/save.svg' />
                  </div> */}
                </div>

                <div className='shipping'>
                  <div className='shippingDetail'>
                    <h3>Shipping Address</h3>
                    <p className='address'>{customer?.fullName}</p>
                    <p>{customer?.shippingAddress?.state}</p>
                    <p>{customer?.shippingAddress?.country}</p>
                  </div>
                  {/* <div className='action'>
                    <Link>Edit</Link>
                    <img src='/assets/icons/save.svg' />
                  </div> */}
                </div>

                <div className='billing'>
                  <div className='shippingDetail'>
                    <h3>Billing Address</h3>
                    <p>Same as shipping address</p>
                  </div>
                  {/* <div className='action'>
                    <Link>Edit</Link>
                    <img src='/assets/icons/save.svg' />
                  </div> */}
                </div>
              </div>

              {/* <div className='market'>
                <div className='header'>
                  <h3>Market</h3>
                  <p className='changeBtn'>Change</p>
                </div>
                <div className='body'>
                  <p className='name'>Primary market</p>
                  <p>United States (USD $)</p>
                </div>
              </div> */}
            </div>
          </Content>
        </div>
      </div>
    </>
  );
};

export default Order;
