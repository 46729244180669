import { Layout, Radio, Checkbox, DatePicker, TimePicker, Select } from "antd";
import React, { useState } from "react";
import BreadCrumbs from "../../components/layout/BreadCrumbs";
import { BiLeftArrowAlt, BiDollar } from "react-icons/bi";
import { FaPercent } from "react-icons/fa";
import { MdOutlineSearch } from "react-icons/md";
import "./discount.scss";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FormError } from "../../components/custom/Custom";
import { generateCode } from "../../utils/helper";
import { addBuyXgetY } from "../../redux/buyXgetYSlice";

const { Content } = Layout;
const { Option } = Select;
const BuyXGetY = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [methodField, setMethodField] = useState("code");
  const [minimumPurchaseField, setMinimumPurchaseField] =
    useState("minimumQty");
  const [discountValueField, setDiscountValueField] = useState("percentage");
  const [discountUsageFiled, setDiscountUsageFiled] = useState(false);
  const [endDateField, setEndDateField] = useState(false);

  const store = useSelector((state) => state?.buyXgetY);
  const loading = store?.loading;
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors },
  } = useForm();

  const onFormSubmit = (values) => {
    const data = {
      method: {
        DISCOUNT_CODE: methodField === "code" ? true : false,
        AUTOMATIC_DISCOUNT: methodField === "automatic" ? true : false,
        data:
          methodField === "code"
            ? values.code
            : methodField === "automatic"
            ? values.title
            : "",
      },

      customerBuys: {
        MINIMUM_PURCHASE_AMOUNT: {
          flag: minimumPurchaseField === "minimumAmount" ? true : false,
          minimumPurchaseAmount:
            minimumPurchaseField === "minimumAmount"
              ? Number(values.minimumAmount)
              : 0,
        },
        MINIMUM_QUANTITY_OF_ITEMS: {
          flag: minimumPurchaseField === "minimumQty" ? true : false,
          minimumQuantityOfItems:
            minimumPurchaseField === "minimumQty"
              ? Number(values.minimumQty)
              : 0,
        },
      },
      customerGet:
        minimumPurchaseField === "minimumAmount"
          ? Number(values.getAmount)
          : minimumPurchaseField === "minimumQty"
          ? Number(values.getQty)
          : "",

      activeDates: {
        startDate: values.startDate,
        endDate: values.endDate,
        startTime: values.startTime,
        endTime: "00:00:00",
      },
      setMaximumNumberOfUsesPerOrder: {
        flag: discountUsageFiled,
        data: discountUsageFiled ? Number(values?.discountUsage) : 0,
      },
      active: true,
    };
    dispatch(addBuyXgetY({ data, navigate }));
  };

  return (
    <>
      <div className='buyXGetYContainer'>
        <div className='addBuyXGetY'>
          <div className='pageHeader'>
            <BiLeftArrowAlt
              className='backBtn'
              onClick={() => {
                navigate("/discount");
              }}
            />
            <BreadCrumbs title='Create product discount' />
          </div>

          <form onSubmit={handleSubmit(onFormSubmit)}>
            <Content className='buyXGetYContent'>
              {/* right block */}
              <div className='rightBlock'>
                <div className='ammount'>
                  <div className='header'>
                    <h3>Buy X get Y</h3>
                    <p>Product discount</p>
                  </div>

                  <div className='body'>
                    <div className='method'>
                      <h3>Method</h3>
                      <Radio.Group
                        className='methodRadioBtn'
                        value={methodField}
                        onChange={(e) => {
                          setMethodField(e.target.value);
                        }}>
                        <Radio value='code'>Discount code</Radio>
                        <Radio value='automatic'>Automatic discount</Radio>
                      </Radio.Group>
                    </div>
                    <div className='code'>
                      {methodField === "code" ? (
                        <>
                          <div className='discountCode'>
                            <label htmlFor=''>Discount code</label>
                            <div className='discountCodeFiled'>
                              <input
                                type='text'
                                placeholder='Discount Code'
                                name='code'
                                {...register("code", {
                                  required: "code is required",
                                })}
                              />
                              <img src='/assets/icons/addressCard.svg' />
                            </div>
                            {errors?.code?.message ? (
                              <FormError message={errors?.code?.message} />
                            ) : (
                              <p>Customers must enter this code at checkout.</p>
                            )}
                          </div>

                          <button
                            type='button'
                            onClick={() => setValue("code", generateCode(9))}>
                            Generate
                          </button>
                        </>
                      ) : methodField === "automatic" ? (
                        <>
                          <div className='discountCode'>
                            <label htmlFor=''>Title</label>
                            <div className='discountCodeFiled'>
                              <input
                                type='text'
                                placeholder='Title'
                                name='title'
                                {...register("title", {
                                  required: "title is required",
                                })}
                              />
                              <img src='/assets/icons/addressCard.svg' />
                            </div>
                            {errors?.title?.message ? (
                              <FormError message={errors?.title?.message} />
                            ) : (
                              <p>Customers must enter this code at checkout.</p>
                            )}
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>

                <div className='minimumRequired'>
                  <div className='customerSpend'>
                    <h3>Customer spends</h3>
                    <Radio.Group
                      className='radioBtn'
                      value={minimumPurchaseField}
                      onChange={(e) => {
                        setMinimumPurchaseField(e.target.value);
                      }}>
                      <Radio value='minimumQty'>
                        Minimum quantity of items
                      </Radio>

                      <Radio value='minimumAmount'>
                        Minimum purchase amount ($)
                      </Radio>
                    </Radio.Group>
                    <div className='body'>
                      <div className='wrapper'>
                        <div className='quantity'>
                          {minimumPurchaseField === "minimumQty" && (
                            <>
                              <label htmlFor=''>Quantity</label>
                              <div className='quantityFiled'>
                                <input
                                  type='number'
                                  {...register("minimumQty", {
                                    required: "minimum quantity is required",
                                  })}
                                  min='0'
                                />
                              </div>
                              <FormError
                                message={errors?.minimumQty?.message}
                              />
                            </>
                          )}
                          {minimumPurchaseField === "minimumAmount" && (
                            <>
                              <label htmlFor=''>Amount</label>
                              <div className='quantityFiled'>
                                <BiDollar className='icon' />
                                <input
                                  placeholder='0.00'
                                  type='number'
                                  {...register("minimumAmount", {
                                    required: "minimum amount is required",
                                  })}
                                  min='0'
                                />
                              </div>
                              <FormError
                                message={errors?.minimumAmount?.message}
                              />
                            </>
                          )}
                        </div>
                        {/* <div className='items'>
                          <label htmlFor=''>Product status</label>

                          <Select
                            defaultValue='lucy'
                            suffixIcon={
                              <img src='/assets/icons/selectArrows.svg' />
                            }>
                            <Option value='jack'>Active</Option>
                            <Option value='lucy'>Draft</Option>
                            <Option value='Yiminghe'>Trash</Option>
                          </Select>
                        </div> */}
                      </div>
                      {/* <div className='wrapper'>
                        <div className='search'>
                          <div className='searchField'>
                            <img src='/assets/icons/search.svg' />
                            <input type='text' placeholder='search products' />
                          </div>
                        </div>
                        <button>Browse</button>
                      </div> */}
                    </div>
                  </div>

                  <div className='customerGet'>
                    <h3>Customer gets</h3>
                    <p>
                      Customers must add the quantity of items specified below
                      to their cart.
                    </p>
                    <div className='body'>
                      <div className='wrapper'>
                        <div className='quantity'>
                          {minimumPurchaseField === "minimumQty" && (
                            <>
                              <label htmlFor=''>Quantity</label>
                              <div className='quantityFiled'>
                                <input
                                  type='number'
                                  {...register("getQty", {
                                    required: "quantity is required",
                                  })}
                                  min='0'
                                />
                              </div>
                              <FormError message={errors?.getQty?.message} />
                            </>
                          )}
                          {minimumPurchaseField === "minimumAmount" && (
                            <>
                              <label htmlFor=''>Amount</label>
                              <div className='quantityFiled'>
                                <BiDollar className='icon' />
                                <input
                                  placeholder='0.00'
                                  type='number'
                                  {...register("getAmount", {
                                    required: "amount is required",
                                  })}
                                  min='0'
                                />
                              </div>
                              <FormError message={errors?.getAmount?.message} />
                            </>
                          )}
                        </div>
                        {/* <div className='items'>
                          <label htmlFor=''>Product status</label>

                          <Select
                            defaultValue='lucy'
                            suffixIcon={
                              <img src='/assets/icons/selectArrows.svg' />
                            }>
                            <Option value='jack'>Active</Option>
                            <Option value='lucy'>Draft</Option>
                            <Option value='Yiminghe'>Trash</Option>
                          </Select>
                        </div> */}
                      </div>
                      {/* <div className='wrapper'>
                        <div className='search'>
                          <div className='searchField'>
                            <img src='/assets/icons/search.svg' />
                            <input type='text' placeholder='search products' />
                          </div>
                        </div>
                        <button>Browse</button>
                      </div> */}

                      {/* <h4>At a discounted value</h4>

                      <Radio.Group
                        className='radioBtn'
                        value={discountValueField}
                        onChange={(e) => {
                          setDiscountValueField(e.target.value);
                        }}>
                        <Radio value={"percentage"}>Percentage</Radio>
                        {discountValueField === "percentage" && (
                          <div className='discountedValueFiled'>
                            <FaPercent className='icon' />
                            <input type='number' min='0' />
                          </div>
                        )}
                        <Radio value={"free"}>Free</Radio>
                      </Radio.Group> */}
                    </div>
                  </div>

                  <div className='footer'>
                    <Checkbox
                      onChange={(e) =>
                        setDiscountUsageFiled(e?.target?.checked)
                      }>
                      Set a maximum number of uses per order
                    </Checkbox>
                    {discountUsageFiled && (
                      <>
                        <div className='discountedUsageFiled'>
                          <input
                            type='number'
                            {...register("discountUsage", {
                              required: "discount limit is required",
                            })}
                            min='0'
                          />
                        </div>
                        <FormError message={errors?.discountUsage?.message} />
                      </>
                    )}
                  </div>
                </div>

                <div className='activeDates'>
                  <h3>Active dates</h3>
                  <div className='wrapper'>
                    <div className='dateFiledWrapper'>
                      <label>Start date</label>
                      <div className='filed'>
                        <img src='/assets/icons/calendar.svg' />
                        <Controller
                          control={control}
                          name='startDate'
                          rules={{ required: "start date is required" }}
                          render={({ field }) => (
                            <DatePicker
                              onChange={(_, dateString) =>
                                field.onChange(dateString)
                              }
                              selected={field.value}
                              bordered={false}
                              suffixIcon={false}
                            />
                          )}
                        />
                      </div>
                      <FormError message={errors?.startDate?.message} />
                    </div>

                    <div className='timeFiledWrapper'>
                      <label>Start time (CDT)</label>
                      <div className='filed'>
                        <img src='/assets/icons/clock.svg' />
                        <Controller
                          control={control}
                          name='startTime'
                          rules={{ required: "start time is required" }}
                          render={({ field }) => (
                            <TimePicker
                              onChange={(_, timeString) =>
                                field.onChange(timeString)
                              }
                              selected={field.value}
                              bordered={false}
                              suffixIcon={false}
                            />
                          )}
                        />
                      </div>
                      <FormError message={errors?.startTime?.message} />
                    </div>
                  </div>
                  <Checkbox
                    className='setEndDate'
                    onChange={(e) => setEndDateField(e.target.checked)}>
                    Set end date
                  </Checkbox>
                  {endDateField && (
                    <div className='dateFiledWrapper'>
                      <label>End date</label>
                      <div className='filed'>
                        <img src='/assets/icons/calendar.svg' />
                        <Controller
                          control={control}
                          name='endDate'
                          rules={{ required: "end date is required" }}
                          render={({ field }) => (
                            <DatePicker
                              onChange={(_, dateString) =>
                                field.onChange(dateString)
                              }
                              selected={field.value}
                              bordered={false}
                              suffixIcon={false}
                            />
                          )}
                        />
                      </div>
                      <FormError message={errors?.endDate?.message} />
                    </div>
                  )}
                </div>
              </div>

              {/* left block */}
              <div className='leftBlock'>
                <div className='summary'>
                  <div className='header'>
                    <h3>Summary</h3>
                  </div>
                  <div className='body'>
                    <p>No discount code yet.</p>
                    <div className='list'>
                      <h3>Type and method</h3>
                      <ul>
                        <li>Amount off products</li>
                        <li>Code</li>
                      </ul>
                      <h3>Details</h3>
                    </div>
                  </div>

                  <div className='footer'>
                    <h3>Performance</h3>
                    <p>Discount is not active yet.</p>
                  </div>
                </div>
              </div>
            </Content>
            <div className='pageFooter'>
              <button
                type='button'
                className='discard'
                onClick={() => {
                  navigate("/discount");
                }}>
                Discard
              </button>
              <button type='submit' className='save'>
                {loading ? "Loading..." : "Save discount"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default BuyXGetY;
