export const basePath = "https://backend.pictings.com/api/v1/";
// export const basePath = "localhost:5000/";
export const APIurls = {
  signin: "user/loginAdmin",
  adminProfile: "user/me",

  //orders
  getAllOrders: "order",
  getAllOrdersCanada: "order/get/fromCanada",
  getCompletedOrders: "order/get/complete",
  getPendingOrders: "order/get/pending",
  getCanceledOrders: "order/get/canceled",
  getSingleOrder: "order",
  updateOrder: "order/editOrderStatus",

  //gift card
  giftcard: "giftCard",
  SingleGiftcard: "giftCard",
  addGiftCard: "giftCard",
  issueGiftCard: "giftCard/issueGiftcard",
  deleteGiftCard: "giftCard",
  updateGiftCard: "giftCard/edit",
  giftCardSearch: "giftCard/search",
  updateGiftCardImage: "giftCard/updateMedia",

  //products
  getAllProducts: "frame",
  getActiveProducts: "frame/get/active",
  getClosedProducts: "frame/get/closed",
  getArchivedProducts: "frame/get/archived",
  getSingleProducts: "frame",
  addProduct: "frame",
  updateProduct: "frame",
  deleteProduct: "frame",
  updateProductImage: "frame/updateMedia",
  productSearch: "frame/search",

  //discount
  getAllAmountOffProduct: "discount/amount-off-product",
  addAmountOffProduct: "discount/amount-off-product",
  deleteAmountOffProduct: "discount/amount-off-product",

  getAllAmountOffOrder: "discount/amount-off-order",
  addAmountOffOrder: "discount/amount-off-order",
  deleteAmountOffOrder: "discount/amount-off-order",

  getAllBuyXgetY: "discount/buy-x-get-y",
  addBuyXgetY: "discount/buy-x-get-y",
  deleteBuyXgetY: "discount/buy-x-get-y",

  getAllFreeShipping: "discount/free-shipping",
  addFreeShipping: "discount/free-shipping",
  deleteFreeShipping: "discount/free-shipping",

  //customers
  getAllCustomer: "user/customers",
  customerSearch: "user/customers/search",
};
