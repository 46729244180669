import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiRequests } from "../services/ApiRequest";
import { toast } from "react-toastify";

//get all amount off order discount
export const getAllAmountOffOrder = createAsyncThunk(
  "amountOffOrder/getAllAmountOffOrder",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.getAllAmountOffOrder();
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  }
);

// add amount off order discount
export const addAmountOffOrder = createAsyncThunk(
  "amountOffOrder/addAmountOffOrder",
  async ({ data, navigate }, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.addAmountOffOrder(data);
      navigate("/discount");
      toast.success("Discount is added successfully!", {
        position: "top-right",
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "light",
      });
    } catch (error) {
      toast.error(error?.response?.data?.msg, {
        position: "top-right",
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "light",
      });
    }
  }
);

// delete Amount Off Order
export const deleteAmountOffOrder = createAsyncThunk(
  "amountOffOrder/deleteAmountOffOrder",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.deleteAmountOffOrder(data);
      console.log(response);
      toast.success("Amount Off Order discount deleted Successfully!", {
        position: "top-right",
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "light",
      });
    } catch (error) {
      console.log(error);
    }
  }
);

const initialState = {
  amountOffOrder: [],
  loading: false,
};
const amountOffOrderSlice = createSlice({
  name: "amountOffOrder",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllAmountOffOrder.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllAmountOffOrder.fulfilled, (state, action) => {
        state.amountOffOrder = action.payload;
        state.loading = false;
      })

      .addCase(addAmountOffOrder.pending, (state) => {
        state.loading = true;
      })
      .addCase(addAmountOffOrder.fulfilled, (state) => {
        state.loading = false;
      })

      .addCase(deleteAmountOffOrder.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteAmountOffOrder.fulfilled, (state) => {
        state.loading = false;
      });
  },
});

export default amountOffOrderSlice.reducer;
