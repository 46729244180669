import { Layout, Radio, Checkbox, DatePicker, TimePicker } from "antd";
import React, { useState } from "react";
import BreadCrumbs from "../../components/layout/BreadCrumbs";
import { BiLeftArrowAlt, BiDollar } from "react-icons/bi";
import { FaPercent } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import "./discount.scss";
import { generateCode } from "../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import { addAmountOffOrder } from "../../redux/amountOffOrderSlice";
import { FormError } from "../../components/custom/Custom";

const { Content } = Layout;

const valueSwitchOption = [
  {
    label: "Percentage",
    value: "percentage",
  },
  {
    label: "Fixed amount",
    value: "fixedAmount",
  },
];

const AmountOffOrder = () => {
  const [methodField, setMethodField] = useState("code");
  const [valueField, setValueField] = useState("percentage");
  const [minimumPurchaseField, setMinimumPurchaseField] = useState("noMinimum");
  const [endDateField, setEndDateField] = useState(false);

  const store = useSelector((state) => state?.amountOffOrder);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onFormSubmit = (values) => {
    const data = {
      method: {
        DISCOUNT_CODE: methodField === "code" ? true : false,
        AUTOMATIC_DISCOUNT: methodField === "automatic" ? true : false,
        data:
          methodField === "code"
            ? values.code
            : methodField === "automatic"
            ? values.title
            : "",
      },
      value: {
        discountType:
          valueField === "percentage"
            ? "Percent"
            : valueField === "fixedAmount"
            ? "Amount"
            : "",
        total:
          valueField === "percentage"
            ? Number(values.percentage)
            : valueField === "fixedAmount"
            ? Number(values.fixedAmount)
            : 0,
      },
      minimumPurchaseRequirements: {
        NO_MINIMUM_REQUIREMENTS:
          minimumPurchaseField === "noMinimum" ? true : false,
        MINIMUM_PURCHASE_AMOUNT: {
          flag: minimumPurchaseField === "minimumAmount" ? true : false,
          minimumPurchaseAmount:
            minimumPurchaseField === "minimumAmount"
              ? values.minimumAmount
              : "0",
        },
        MINIMUM_QUANTITY_OF_ITEMS: {
          flag: minimumPurchaseField === "minimumQty" ? true : false,
          minimumQuantityOfItems:
            minimumPurchaseField === "minimumQty" ? values.minimumQty : "0",
        },
      },
      activeDates: {
        startDate: values.startDate,
        endDate: values.endDate,
        startTime: values.startTime,
        endTime: "00:00:00",
      },
      active: true,
    };
    dispatch(addAmountOffOrder({ data, navigate }));
  };

  return (
    <>
      <div className='ammountOffOrderContainer'>
        <div className='addAmmountOffOrder'>
          <div className='pageHeader'>
            <BiLeftArrowAlt
              className='backBtn'
              onClick={() => {
                navigate("/discount");
              }}
            />
            <BreadCrumbs title='Create product discount' />
          </div>
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <Content className='ammountOffOrderContent'>
              {/* right block */}
              <div className='rightBlock'>
                <div className='ammount'>
                  <div className='header'>
                    <h3>Amount off order</h3>
                    <p>Order discount</p>
                  </div>

                  <div className='body'>
                    <div className='method'>
                      <h3>Method</h3>
                      <Radio.Group
                        className='methodRadioBtn'
                        value={methodField}
                        onChange={(e) => {
                          setMethodField(e.target.value);
                        }}>
                        <Radio value='code'>Discount code</Radio>
                        <Radio value='automatic'>Automatic discount</Radio>
                      </Radio.Group>
                    </div>
                    <div className='code'>
                      {methodField === "code" ? (
                        <>
                          <div className='discountCode'>
                            <label htmlFor=''>Discount code</label>
                            <div className='discountCodeFiled'>
                              <input
                                type='text'
                                placeholder='Golden Frame'
                                name='code'
                                {...register("code", {
                                  required: "code is required",
                                })}
                              />
                              <img src='/assets/icons/addressCard.svg' />
                            </div>
                            {errors?.code?.message ? (
                              <FormError message={errors?.code?.message} />
                            ) : (
                              <p>Customers must enter this code at checkout.</p>
                            )}
                          </div>
                          <button
                            type='button'
                            onClick={() => setValue("code", generateCode(9))}>
                            Generate
                          </button>
                        </>
                      ) : methodField === "automatic" ? (
                        <>
                          <div className='discountCode'>
                            <label htmlFor=''>Title</label>
                            <div className='discountCodeFiled'>
                              <input
                                type='text'
                                placeholder='Title'
                                name='title'
                                {...register("title", {
                                  required: "title is required",
                                })}
                              />
                              <img src='/assets/icons/addressCard.svg' />
                            </div>
                            {errors?.title?.message ? (
                              <FormError message={errors?.title?.message} />
                            ) : (
                              <p>Customers must enter this code at checkout.</p>
                            )}
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>

                <div className='value'>
                  <h3>Value</h3>

                  <div className='wrapper'>
                    <div className='switch'>
                      <Radio.Group
                        options={valueSwitchOption}
                        onChange={(e) => {
                          setValueField(e.target.value);
                        }}
                        value={valueField}
                        optionType='button'
                        buttonStyle='solid'
                      />
                    </div>
                    <div className='fieldWrapper'>
                      {valueField === "percentage" ? (
                        <>
                          <div className='valueFiled'>
                            <FaPercent className='icon' />
                            <input
                              type='number'
                              min='0'
                              placeholder='0.00'
                              name='percentage'
                              {...register("percentage", {
                                required: "percentage is required",
                              })}
                            />
                          </div>
                          <FormError message={errors?.percentage?.message} />
                        </>
                      ) : valueField === "fixedAmount" ? (
                        <>
                          <div className='valueFiled'>
                            <BiDollar className='UsdIcon' />
                            <input
                              type='number'
                              min='0'
                              placeholder='0.00'
                              name='fixedAmount'
                              {...register("fixedAmount", {
                                required: "amount is required is required",
                              })}
                            />
                          </div>
                          <FormError message={errors?.fixedAmount?.message} />
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>

                <div className='minimumRequired'>
                  <h3>Minimum purchase requirements</h3>
                  <Radio.Group
                    className='radioBtn'
                    value={minimumPurchaseField}
                    onChange={(e) => {
                      setMinimumPurchaseField(e.target.value);
                    }}>
                    <Radio value='noMinimum'>No minimum requirements</Radio>

                    <Radio value='minimumAmount'>
                      Minimum purchase amount ($)
                    </Radio>
                    {minimumPurchaseField === "minimumAmount" && (
                      <>
                        <div className='discountedValueFiled'>
                          <BiDollar className='icon' />
                          <input
                            type='number'
                            min='0'
                            {...register("minimumAmount", {
                              required: "minimum amount is required",
                            })}
                          />
                        </div>
                        <p>Applies to all products.</p>
                        <FormError message={errors?.minimumAmount?.message} />
                      </>
                    )}
                    <Radio value='minimumQty'>Minimum quantity of items</Radio>
                    {minimumPurchaseField === "minimumQty" && (
                      <>
                        <div className='discountedValueFiled'>
                          <input
                            type='number'
                            min='0'
                            {...register("minimumQty", {
                              required: "minimum quantity is required",
                            })}
                          />
                        </div>
                        <FormError message={errors?.minimumQty?.message} />
                      </>
                    )}
                  </Radio.Group>
                </div>

                <div className='activeDates'>
                  <h3>Active dates</h3>
                  <div className='wrapper'>
                    <div className='dateFiledWrapper'>
                      <label>Start date</label>
                      <div className='filed'>
                        <img src='/assets/icons/calendar.svg' />
                        <Controller
                          control={control}
                          name='startDate'
                          rules={{ required: "start date is required" }}
                          render={({ field }) => (
                            <DatePicker
                              onChange={(_, dateString) =>
                                field.onChange(dateString)
                              }
                              selected={field.value}
                              bordered={false}
                              suffixIcon={false}
                            />
                          )}
                        />
                      </div>
                      <FormError message={errors?.startDate?.message} />
                    </div>

                    <div className='timeFiledWrapper'>
                      <label>Start time (CDT)</label>
                      <div className='filed'>
                        <img src='/assets/icons/clock.svg' />
                        <Controller
                          control={control}
                          name='startTime'
                          rules={{ required: "start time is required" }}
                          render={({ field }) => (
                            <TimePicker
                              onChange={(_, timeString) =>
                                field.onChange(timeString)
                              }
                              selected={field.value}
                              bordered={false}
                              suffixIcon={false}
                            />
                          )}
                        />
                      </div>
                      <FormError message={errors?.startTime?.message} />
                    </div>
                  </div>
                  <Checkbox
                    className='setEndDate'
                    onChange={(e) => setEndDateField(e.target.checked)}>
                    Set end date
                  </Checkbox>
                  {endDateField && (
                    <div className='dateFiledWrapper'>
                      <label>End date</label>
                      <div className='filed'>
                        <img src='/assets/icons/calendar.svg' />
                        <Controller
                          control={control}
                          name='endDate'
                          rules={{ required: "end date is required" }}
                          render={({ field }) => (
                            <DatePicker
                              onChange={(_, dateString) =>
                                field.onChange(dateString)
                              }
                              selected={field.value}
                              bordered={false}
                              suffixIcon={false}
                            />
                          )}
                        />
                      </div>
                      <FormError message={errors?.endDate?.message} />
                    </div>
                  )}
                </div>
              </div>

              {/* left block */}
              <div className='leftBlock'>
                <div className='summary'>
                  <div className='header'>
                    <h3>Summary</h3>
                  </div>
                  <div className='body'>
                    <p>No discount code yet.</p>
                    <div className='list'>
                      <h3>Type and method</h3>
                      <ul>
                        <li>Amount off products</li>
                        <li>Code</li>
                      </ul>
                      <h3>Details</h3>
                    </div>
                  </div>

                  <div className='footer'>
                    <h3>Performance</h3>
                    <p>Discount is not active yet.</p>
                  </div>
                </div>
              </div>
            </Content>
            <div className='pageFooter'>
              <button
                type='button'
                className='discard'
                onClick={() => {
                  navigate("/discount");
                }}>
                Discard
              </button>
              <button type='save' className='save'>
                {store?.loading ? "Loading..." : "Save discount"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AmountOffOrder;
