import React from "react";
import "antd/dist/antd.css";
import "./styles/app.scss";
import { Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// layouts
import MainLayout from "./components/layout";

// pages
import Login from "./pages/auth/Login";

import Dashboard from "./pages/dashboard/Dashboard";
import GiftCards from "./pages/giftcards/GiftCards";
import SingleGiftcard from "./pages/giftcards/SingleGiftcard";
import IssueGiftCards from "./pages/giftcards/IssueGiftCards";
import AddGiftCard from "./pages/giftcards/AddGiftCard";
import Orders from "./pages/orders/Orders";
import Order from "./pages/orders/Order";
import Drafts from "./pages/orders/Drafts";
import Customers from "./pages/customers/Customers";
import Products from "./pages/products/Products";
import AddProduct from "./pages/products/AddProduct";
import UpdateProduct from "./pages/products/UpdateProduct";
import SingleProduct from "./pages/products/SingleProduct";
import Discount from "./pages/discount/Discount";
import AmountOffOrder from "./pages/discount/AmountOffOrder";
import AmountOffProducts from "./pages/discount/AmountOffProducts";
import BuyXGetY from "./pages/discount/BuyXGetY";
import FreeShipping from "./pages/discount/FreeShipping";
import UpdateGiftCard from "./pages/giftcards/UpdateGiftCard";

const App = () => {
  return (
    <>
      <Routes>
        <Route path='/login' element={<Login />} />

        <Route path='/' element={<MainLayout />}>
          <Route path='/' element={<Dashboard />} />
          <Route path='/gift-cards' element={<GiftCards />} />
          <Route path='/gift-cards/:id' element={<SingleGiftcard />} />
          <Route path='/gift-cards/:id/edit' element={<UpdateGiftCard />} />
          <Route path='/issue-gift-cards' element={<IssueGiftCards />} />
          <Route path='/add-gift-cards' element={<AddGiftCard />} />
          <Route path='/orders' element={<Orders />} />
          <Route path='/order/:orderId' element={<Order />} />
          <Route path='/drafts' element={<Drafts />} />
          <Route path='/customers' element={<Customers />} />
          <Route path='/products' element={<Products />} />
          <Route path='/products/:id' element={<SingleProduct />} />
          <Route path='/add-product' element={<AddProduct />} />
          <Route path='/products/:id/edit' element={<UpdateProduct />} />
          <Route path='/discount' element={<Discount />} />
          <Route
            path='/discount/amount-off-order'
            element={<AmountOffOrder />}
          />
          <Route
            path='/discount/amount-off-product'
            element={<AmountOffProducts />}
          />
          <Route path='/discount/buy-x-get-y' element={<BuyXGetY />} />
          <Route path='/discount/free-shipping' element={<FreeShipping />} />
        </Route>
      </Routes>

      <ToastContainer theme='colored' />
    </>
  );
};

export default App;
