import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiRequests } from "../services/ApiRequest";
import { toast } from "react-toastify";

//get all buyXgetY discount
export const getAllBuyXgetY = createAsyncThunk(
  "buyXgetY/getAllBuyXgetY",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.getAllBuyXgetY();
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  }
);

// add buyXgetY discount
export const addBuyXgetY = createAsyncThunk(
  "buyXgetY/addBuyXgetY",
  async ({ data, navigate }, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.addBuyXgetY(data);
      navigate("/discount");
      toast.success("Discount is added successfully!", {
        position: "top-right",
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "light",
      });
    } catch (error) {
      toast.error(error?.response?.data?.msg, {
        position: "top-right",
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "light",
      });
    }
  }
);

// delete buyXgetY
export const deleteBuyXgetY = createAsyncThunk(
  "buyXgetY/deleteBuyXgetY",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.deleteBuyXgetY(data);
      console.log(response);
      toast.success("discount deleted Successfully!", {
        position: "top-right",
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "light",
      });
    } catch (error) {
      console.log(error);
    }
  }
);

const initialState = {
  buyXgetY: [],
  loading: false,
};
const buyXgetYSlice = createSlice({
  name: "buyXgetY",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllBuyXgetY.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllBuyXgetY.fulfilled, (state, action) => {
        state.loading = false;
      })

      .addCase(addBuyXgetY.pending, (state) => {
        state.loading = true;
      })
      .addCase(addBuyXgetY.fulfilled, (state) => {
        state.loading = false;
      })

      .addCase(deleteBuyXgetY.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteBuyXgetY.fulfilled, (state) => {
        state.loading = false;
      });
  },
});

export default buyXgetYSlice.reducer;
