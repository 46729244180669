import React, { useEffect, useState } from "react";
import { Layout, Table } from "antd";
import "./customers.scss";
import BreadCrumbs from "../../components/layout/BreadCrumbs";
import { useDispatch, useSelector } from "react-redux";
import { getAllCustomer } from "../../redux/customerSlice";
import CustomersTable from "../../components/customers/CustomersTable";

const { Content } = Layout;

const Customers = () => {
  const dispatch = useDispatch();

  const store = useSelector((state) => state?.customer);
  const customers = store?.customers;
  const loading = store?.loading;

  useEffect(() => {
    dispatch(getAllCustomer());
  }, []);

  return (
    <>
      <div className='pageHeader'>
        <BreadCrumbs title='Customers' />
        <div className='pageHeaderAction'>
          {/* <button type='button' className='exportbtn'>
            Export
          </button> */}
          {/* <button type='button' className='addbtn'>
            Add customer
          </button> */}
        </div>
      </div>
      <Content className='customers'>
        {/* <div className='filters'>
          <h3>1 customer</h3>
          <p>No filters applied</p>
        </div> */}

        <CustomersTable allCustomers={customers} loading={loading} />
      </Content>
    </>
  );
};

export default Customers;
