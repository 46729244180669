import { Table } from "antd";
import React, { useEffect, useState } from "react";
import { IoSearch } from "react-icons/io5";
import { TbArrowsSort } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { customerSearch } from "../../redux/customerSlice";

const CustomersTable = ({ allCustomers, loading }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [customers, setCustomers] = useState(allCustomers);
  const [searchValue, setSearchValue] = useState("");
  const dispatch = useDispatch();

  const store = useSelector((state) => state?.customer);
  const searchedCustomers = store?.searchedCustomers;

  useEffect(() => {
    if (searchValue !== "") {
      setCustomers(searchedCustomers);
    } else {
      setCustomers(allCustomers);
    }
  }, [allCustomers, searchedCustomers]);

  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const columns = [
    {
      title: "Customer name",
      dataIndex: "customerName",
    },
    // {
    //   title: "Email subscription",
    //   dataIndex: "emailSubscription",
    // },
    {
      title: "Location",
      dataIndex: "location",
    },
    {
      title: "Orders",
      dataIndex: "orders",
    },
    {
      title: "Spent",
      dataIndex: "spent",
    },
  ];

  const data = [];

  customers?.map((customer) => {
    data.push({
      key: customer?._id,
      customerName: customer?.fullName,
      // emailSubscription: <p className='successBadge'>Subscribed</p>,
      location: `${customer?.shippingAddress?.city}, ${customer?.shippingAddress?.country}`,
      orders: `${customer?.orders} orders`,
      spent: `$${customer?.spent.toFixed(2)}`,
    });
  });

  return (
    <div className='customersTableContainer'>
      <div className='customersTableHeader'>
        <div className='customersSearch'>
          <IoSearch className='searchIcon' />
          <input
            type='text'
            placeholder='Search Customers'
            onChange={(e) => {
              setSearchValue(e.target.value);
              dispatch(
                customerSearch({
                  name: e.target.value,
                })
              );
              // setCustomer(
              //   allCustomers.filter((product) =>
              //     String(product?.fullName)
              //       .toUpperCase()
              //       .includes(String(e.target.value).toUpperCase())
              //   )
              // );
            }}
          />
        </div>

        {/* <button type='button'>
          <TbArrowsSort className='sortIcon' />
          <span>Sort</span>
        </button> */}
      </div>
      <Table
        className='customerTable'
        rowSelection={rowSelection}
        loading={loading}
        columns={columns}
        dataSource={data}
        pagination={{
          defaultPageSize: 7,
          showSizeChanger: false,
          hideOnSinglePage: true,
        }}
      />
    </div>
  );
};

export default CustomersTable;
