import { Layout, Table } from "antd";
import React, { useEffect } from "react";
import BreadCrumbs from "../../components/layout/BreadCrumbs";
import { BiLeftArrowAlt } from "react-icons/bi";
import { useNavigate, useParams } from "react-router-dom";
import "./products.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  getSingleProducts,
  unSetSingleProduct,
} from "../../redux/productSlice";
import Loading from "../../components/custom/Loading";

const { Content } = Layout;

const SingleProduct = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    dispatch(getSingleProducts(params?.id));
    return () => {
      dispatch(unSetSingleProduct());
    };
  }, [params?.id]);

  const store = useSelector((state) => state?.product);
  const product = store?.singleProduct;

  // varient table columns and data
  const columns = [
    {
      title: "Variant",
      dataIndex: "variant",
    },
    {
      title: "Price",
      dataIndex: "price",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
    },
  ];

  const data = [
    {
      key: 0,
      variant: "Small",
      price: product?.variant?.small?.price,
      quantity: product?.variant?.small?.quantity,
    },
    {
      key: 1,
      variant: "Medium",
      price: product?.variant?.medium?.price,
      quantity: product?.variant?.medium?.quantity,
    },
  ];

  if (store?.loading) {
    return <Loading />;
  }
  return (
    <>
      <div className='singleProductContainer'>
        <div className='singleProduct'>
          <div className='pageHeader'>
            <BiLeftArrowAlt
              className='backBtn'
              onClick={() => {
                navigate("/products");
              }}
            />
            <BreadCrumbs title={product?.frameTitle} />
          </div>
          <Content className='singleProductContent'>
            {/* right block */}
            <div className='rightBlock'>
              <div className='productDetail'>
                <img src={product?.frame} alt='' />
              </div>

              <div className='pricing'>
                <h3>Pricing</h3>

                <div className='pricingDetail'>
                  <div>
                    <h3>Price</h3>
                    <p>$ {Number(product?.pricing?.price).toFixed(2)}</p>
                  </div>
                  <div>
                    <h3>Compare at price</h3>
                    <p>
                      $ {Number(product?.pricing?.compareAtPrice).toFixed(2)}
                    </p>
                  </div>
                </div>

                <div className='pricingDetail'>
                  <div>
                    <h3>Charge tax on this product</h3>
                    <p> {product?.pricing?.chargeTax ? "Yes" : "No"}</p>
                  </div>
                  <div>
                    <h3>Cost per item</h3>
                    <p>$ {Number(product?.pricing?.costPerItem).toFixed(2)}</p>
                  </div>
                </div>
              </div>

              <div className='inventory'>
                <h3>Inventory</h3>
                <div className='inventoryDetail'>
                  <div>
                    <h3>SKU (Stock Keeping Unit)</h3>
                    <p> {String(product?.inventory?.stockKeepingUnit)}</p>
                  </div>
                  <div>
                    <h3>Barcode (ISBN, UPC, GTIN, etc.)</h3>
                    <p> {String(product?.inventory?.barcode)}</p>
                  </div>
                </div>
                <div className='inventoryDetail'>
                  <div>
                    <h3>Continue selling when out of stock</h3>
                    <p>
                      {product?.inventory?.continueSellingWhenOutOfStock
                        ? "Yes"
                        : "No"}
                    </p>
                  </div>
                </div>
                <div className='inventoryDetail'>
                  <div>
                    <h3>Track quantity</h3>
                    <p> {product?.inventory?.trackQuantity ? "Yes" : "No"}</p>
                  </div>
                  <div>
                    <h3>location Name</h3>
                    <p>
                      {String(product?.inventory?.quantity?.[0]?.locationName)}
                    </p>
                  </div>
                  <div>
                    <h3>Available</h3>
                    <p>
                      {String(product?.inventory?.quantity?.[0]?.available)}
                    </p>
                  </div>
                </div>
              </div>

              <div className='shipping'>
                <h3>Shipping</h3>

                <div className='shippingDetail'>
                  <div>
                    <h3>This is a physical product</h3>
                    <p> {product?.shipping ? "Yes" : "No"}</p>
                  </div>
                  <div>
                    <h3>Weight</h3>
                    <p> {String(product?.weight)} lb</p>
                  </div>
                </div>
              </div>

              <div className='option'>
                <h3>Options</h3>

                {product?.option?.haveOptions ? (
                  product?.option?.data?.map((opt) => {
                    return (
                      <div className='optionDetail' key={opt._id}>
                        <div>
                          <h3>Option name</h3>
                          <p>{opt?.name}</p>
                        </div>

                        <div>
                          <h3>Option value</h3>
                          <p>{opt?.value}</p>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className='optionDetail'>
                    <div>
                      <p>N/A</p>
                    </div>
                  </div>
                )}
              </div>

              <div className='variant'>
                <h3>Variants</h3>
                <Table
                  columns={columns}
                  dataSource={data}
                  pagination={false}
                  className='variantTable'
                />
              </div>
            </div>

            {/* left block */}
            <div className='leftBlock'>
              <div className='notes'>
                <h3>Product status</h3>
                <p>{product?.status}</p>
              </div>
            </div>
          </Content>
        </div>
      </div>
    </>
  );
};

export default SingleProduct;
