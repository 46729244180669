import { Layout, Tabs } from "antd";
import React from "react";
import BreadCrumbs from "../../components/layout/BreadCrumbs";
import { useNavigate } from "react-router-dom";
import All from "../../components/orders/tabs/All";
import "./order.scss";

const { Content } = Layout;

const Drafts = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className='pageHeader'>
        <BreadCrumbs title='Drafts' />
        <div className='pageHeaderAction'>
          <button type='button' className='exportbtn'>
            Export
          </button>
          <button type='button' className='addbtn'>
            Create Order
          </button>
        </div>
      </div>
      <Content className='drafts'>
        <Tabs
          defaultActiveKey='1'
          items={[
            {
              label: `All`,
              key: "1",
              children: <All />,
            },
            {
              label: `Open and invoice sent`,
              key: "2",
              children: <All />,
            },
            {
              label: `Open`,
              key: "3",
              children: <All />,
            },
            {
              label: `Invoice sent`,
              key: "4",
              children: <All />,
            },
            {
              label: `Completed`,
              key: "5",
              children: <All />,
            },
          ]}
        />
      </Content>
      <p className='infoText'>Lorem ipsum dolor sit amet, conse</p>
    </>
  );
};

export default Drafts;
