import React from "react";
import { Collapse } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { HiOutlineChevronRight } from "react-icons/hi";

const sideMenuItems1 = [
  {
    icon: (
      <svg
        width='17'
        height='17'
        viewBox='0 0 17 17'
        fill='none'
        className='menuIcon'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M6.45984 16.9199H0.589844V6.50994L8.88984 0.439941L16.9798 6.30994V16.9199H11.2098L11.1098 11.1599L6.55984 11.0599L6.45984 16.9199Z'
          fill='currentColor'
        />
      </svg>
    ),
    key: "home",
    label: "Home",
    path: "/",
  },
  {
    children: [
      { key: "all", label: "All Orders", path: "/orders" },
      // { key: "drafts", label: "Drafts", path: "/drafts" },
      // {
      //   key: "abandonedCheckouts",
      //   label: "Abandoned checkouts",
      //   path: "/abandoned-checkouts",
      // },
    ],
    icon: (
      <svg
        width='17'
        height='18'
        viewBox='0 0 17 18'
        fill='none'
        className='menuIcon'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M0.809805 0.830078V9.62007H2.8098V2.83008H14.6598V9.73007H11.5098C11.5098 9.73007 8.77981 14.1601 5.53981 9.73007H0.799805V17.3001H16.6498V10.7301V9.73007V0.830078H0.809805Z'
          fill='currentColor'
        />
      </svg>
    ),
    key: "orders",
    label: "Orders",
  },
  {
    children: [
      // { key: "inventory", label: "Inventory", path: "/" },
      // { key: "transfers", label: "Transfers", path: "/" },
      // { key: "collections", label: "Collections", path: "/" },
      { key: "allproducts", label: "All Products", path: "/products" },
      { key: "giftCards", label: "Gift cards", path: "/gift-cards" },
    ],
    icon: (
      <svg
        width='18'
        height='18'
        viewBox='0 0 18 18'
        fill='none'
        className='menuIcon'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M9.01973 0.389893L0.469727 8.85989L8.89973 17.3299L17.5797 8.89989V0.389893H9.01973ZM13.6597 6.16989C12.5297 6.16989 11.6197 5.2599 11.6197 4.1299C11.6197 2.9999 12.5297 2.08989 13.6597 2.08989C14.7897 2.08989 15.6997 2.9999 15.6997 4.1299C15.6997 5.2599 14.7897 6.16989 13.6597 6.16989Z'
          fill='currentColor'
        />
      </svg>
    ),
    key: "products",
    label: "Products",
  },
  {
    icon: (
      <svg
        width='15'
        height='18'
        viewBox='0 0 15 18'
        fill='none'
        className='menuIcon'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M7.70024 8.34995C9.88728 8.34995 11.6602 6.57699 11.6602 4.38994C11.6602 2.20289 9.88728 0.429932 7.70024 0.429932C5.51319 0.429932 3.74023 2.20289 3.74023 4.38994C3.74023 6.57699 5.51319 8.34995 7.70024 8.34995Z'
          fill='currentColor'
        />
        <path
          d='M11.6605 10.1699H4.00047C4.00047 10.1699 0.230469 11.9899 0.230469 14.3999C0.230469 16.8099 1.70047 17.2599 1.70047 17.2599H13.1905C13.1905 17.2599 14.7905 16.9699 14.7905 14.3999C14.7905 11.8299 11.6605 10.1699 11.6605 10.1699Z'
          fill='currentColor'
        />
      </svg>
    ),
    key: "customers",
    label: "Customers",
    path: "/customers",
  },
  // {
  //   icon: (
  //     <svg
  //       width='19'
  //       height='18'
  //       viewBox='0 0 19 18'
  //       fill='none'
  //       className='menuIcon'
  //       xmlns='http://www.w3.org/2000/svg'>
  //       <path
  //         d='M16.3698 14.98V7.82999H18.4798L9.4198 0.73999L0.549805 7.82999H2.14981V14.98H1.1298L1.25981 17.53H17.9798L17.9198 14.98H16.3698ZM8.42981 14.11H5.8098V7.89998H8.42981V14.11ZM13.2098 14.11H10.5998V7.89998H13.2198V14.11H13.2098Z'
  //         fill='currentColor'
  //       />
  //     </svg>
  //   ),
  //   key: "finances",
  //   label: "Finances",
  //   path: "/",
  // },
  // {
  //   icon: (
  //     <svg
  //       width='17'
  //       height='18'
  //       viewBox='0 0 17 18'
  //       fill='none'
  //       className='menuIcon'
  //       xmlns='http://www.w3.org/2000/svg'>
  //       <path
  //         d='M2.51984 5.13013H0.0898438V17.0001H2.51984V5.13013Z'
  //         fill='currentColor'
  //       />
  //       <path
  //         d='M7.42957 8.52002H4.80957V17.01H7.42957V8.52002Z'
  //         fill='currentColor'
  //       />
  //       <path
  //         d='M12.2196 2.70996H9.59961V17.01H12.2196V2.70996Z'
  //         fill='currentColor'
  //       />
  //       <path
  //         d='M16.9299 0.280029H14.3799V17H16.9299V0.280029Z'
  //         fill='currentColor'
  //       />
  //     </svg>
  //   ),
  //   key: "Analytics",
  //   label: "Analytics",
  //   path: "/",
  // },
  // {
  //   icon: (
  //     <svg
  //       width='20'
  //       height='21'
  //       viewBox='0 0 20 21'
  //       fill='none'
  //       className='menuIcon'
  //       xmlns='http://www.w3.org/2000/svg'>
  //       <path
  //         d='M10.6297 6.24992C9.91973 5.46992 9.06973 5.19991 8.61973 5.10991L8.50973 5.08992C8.03973 5.04992 6.80973 5.04992 5.68973 5.93992C4.53973 6.85992 4.28973 8.09992 4.23973 8.58992L4.21973 8.76991L4.25973 8.93992C4.41973 9.59992 4.80973 10.5699 5.68973 11.3999C6.27973 11.9599 7.00973 12.3699 7.79973 12.5899L8.33973 10.6699C8.00973 10.5799 7.50973 10.3699 7.05973 9.94993C6.59973 9.51993 6.36973 9.01994 6.24973 8.63994C6.30973 8.35994 6.47973 7.86992 6.93973 7.49992C7.45973 7.07992 8.03973 7.05993 8.27973 7.06993C8.49973 7.11993 8.84973 7.24991 9.14973 7.57991C9.54973 8.01991 9.58973 8.54992 9.58973 8.74992L11.5897 8.75993C11.5997 8.33993 11.5097 7.20992 10.6297 6.24992Z'
  //         fill='currentColor'
  //       />
  //       <path
  //         d='M2.09008 8.57983C2.09008 7.93984 2.22008 5.73986 4.02008 4.00986C5.75008 2.33986 7.88008 2.22984 8.40008 2.23984C9.06008 2.29984 11.3601 2.65986 13.0301 4.63986C14.3801 6.22986 14.6201 7.98984 14.6401 8.89984L16.6401 8.84985C16.6101 7.67985 16.3001 5.40985 14.5501 3.34985C12.4501 0.869855 9.68008 0.359848 8.50008 0.249848C7.47008 0.239848 4.84008 0.439856 2.62008 2.56986C0.250079 4.84986 0.0800781 7.75984 0.0800781 8.64984C0.160078 9.77984 0.590078 12.6698 3.00008 14.9898C4.92008 16.8298 7.13008 17.3898 8.27008 17.5598L8.56008 15.5798C7.65008 15.4498 5.90008 15.0098 4.38008 13.5498C2.49008 11.7098 2.14008 9.40983 2.09008 8.57983Z'
  //         fill='currentColor'
  //       />
  //       <path
  //         d='M9.7002 10.1099L13.9802 20.1999L14.7502 15.2799L19.7902 13.2999L9.7002 10.1099Z'
  //         fill='currentColor'
  //       />
  //     </svg>
  //   ),
  //   key: "Marketing",
  //   label: "Marketing",
  //   path: "/",
  // },
  {
    icon: (
      <svg
        width='19'
        height='20'
        viewBox='0 0 19 20'
        fill='none'
        className='menuIcon'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M18.99 9.94992C18.99 8.96992 18.39 8.1299 17.54 7.7699C17.83 7.3799 18 6.89991 18 6.37991C18 5.07991 16.94 4.0199 15.64 4.0199C15.57 4.0199 15.51 4.02992 15.45 4.03992C15.46 3.93992 15.48 3.83992 15.48 3.72992C15.48 2.42992 14.42 1.3699 13.12 1.3699C12.62 1.3699 12.16 1.52992 11.78 1.78992C11.41 0.979917 10.59 0.409912 9.64004 0.409912C8.68004 0.409912 7.85004 0.989906 7.48004 1.80991C7.08004 1.48991 6.58004 1.2999 6.03004 1.2999C4.73004 1.2999 3.67004 2.35991 3.67004 3.65991C3.67004 3.79991 3.69004 3.93992 3.71004 4.06992C3.54004 4.02992 3.37004 4.00992 3.19004 4.00992C1.89004 4.00992 0.830038 5.0699 0.830038 6.3699C0.830038 6.9699 1.06004 7.50992 1.43004 7.91992C0.610038 8.28992 0.0400391 9.10992 0.0400391 10.0699C0.0400391 11.0799 0.670038 11.9299 1.56004 12.2699C1.12004 12.6999 0.840038 13.2999 0.840038 13.9599C0.840038 15.2599 1.90004 16.3199 3.20004 16.3199C3.37004 16.3199 3.54004 16.2999 3.70004 16.2599C3.69004 16.3299 3.68004 16.3999 3.68004 16.4799C3.68004 17.7799 4.74004 18.8399 6.04004 18.8399C6.52004 18.8399 6.96004 18.6999 7.33004 18.4499C7.68004 19.3099 8.53004 19.9199 9.52004 19.9199C10.67 19.9199 11.63 19.0999 11.84 18.0099C12.24 18.3199 12.74 18.5199 13.29 18.5199C14.59 18.5199 15.65 17.4599 15.65 16.1599C15.65 15.9499 15.61 15.7399 15.56 15.5499C15.68 15.5699 15.81 15.5899 15.94 15.5899C17.24 15.5899 18.3 14.5299 18.3 13.2299C18.3 12.7499 18.15 12.2999 17.91 11.9299C18.55 11.5199 18.99 10.7899 18.99 9.94992ZM4.47004 7.71991C4.47004 6.92991 5.11004 6.28992 5.90004 6.28992C6.69004 6.28992 7.33004 6.92991 7.33004 7.71991C7.33004 8.50991 6.69004 9.1499 5.90004 9.1499C5.11004 9.1399 4.47004 8.50991 4.47004 7.71991ZM6.69004 15.5099L5.10004 14.2999L12.33 4.8399L13.92 6.0499L6.69004 15.5099ZM13.13 13.8199C12.34 13.8199 11.7 13.1799 11.7 12.3899C11.7 11.5999 12.34 10.9599 13.13 10.9599C13.92 10.9599 14.56 11.5999 14.56 12.3899C14.56 13.1799 13.91 13.8199 13.13 13.8199Z'
          fill='currentColor'
        />
      </svg>
    ),
    key: "Discounts",
    label: "Discounts",
    path: "/discount",
  },
];

const sideMenuItems2 = [
  // {
  //   icon: (
  //     <svg
  //       width='18'
  //       height='18'
  //       viewBox='0 0 18 18'
  //       fill='none'
  //       className='menuIcon'
  //       xmlns='http://www.w3.org/2000/svg'>
  //       <path
  //         d='M17.4496 4.75989L14.5596 0.879883H3.05961L1.09961 4.19989C0.969609 4.36989 0.85961 4.54988 0.77961 4.74988V4.75989V4.76987C0.65961 5.05987 0.599609 5.36989 0.599609 5.69989C0.599609 7.05989 1.69961 8.16989 3.06961 8.16989C4.43961 8.16989 5.53961 7.06989 5.53961 5.69989C5.53961 5.57989 5.51961 5.46986 5.50961 5.35986H6.67961C6.65961 5.46986 6.64961 5.57989 6.64961 5.69989C6.64961 7.05989 7.75961 8.16989 9.11961 8.16989C10.4796 8.16989 11.5896 7.06989 11.5896 5.69989C11.5896 5.57989 11.5696 5.46986 11.5596 5.35986H12.5296H12.5596C12.5396 5.46986 12.5296 5.57989 12.5296 5.69989C12.5296 7.05989 13.6296 8.16989 14.9996 8.16989C16.3696 8.16989 17.4696 7.06989 17.4696 5.69989C17.4696 5.41989 17.4096 5.14987 17.3196 4.89987L17.4496 4.75989Z'
  //         fill='currentColor'
  //       />
  //       <path
  //         d='M11.9396 9.1799C11.3096 10.0299 10.3096 10.5899 9.16961 10.5899C8.01961 10.5899 7.00961 10.0199 6.37961 9.15991C5.74961 10.0299 4.73961 10.5899 3.58961 10.5899C2.94961 10.5899 2.35961 10.3999 1.84961 10.0999V17.4399H5.29961C5.29961 15.4699 5.29961 13.4999 5.30961 11.5299C7.74961 11.5299 10.1896 11.5299 12.6196 11.5299V17.4399H16.1496V10.2599C15.7096 10.4699 15.2196 10.5899 14.6996 10.5899C13.5596 10.5899 12.5596 10.0299 11.9396 9.1799Z'
  //         fill='currentColor'
  //       />
  //     </svg>
  //   ),
  //   key: "store",
  //   label: "Online Store",
  //   path: "/",
  // },
];

const { Panel } = Collapse;
const SidebarMenu = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className='sidebarMenu'>
        <div>
          {sideMenuItems1.map((item) => {
            if (item.hasOwnProperty("children")) {
              return (
                <Collapse
                  key={item.key}
                  className='collapseMenu'
                  onChange={() => {
                    navigate(item.path);
                  }}>
                  <Panel
                    className='collapseMenuPanel'
                    header={
                      <div className='collapseMenuItem'>
                        {item.icon}
                        <h3>{item.label}</h3>
                      </div>
                    }
                    key='1'>
                    {item?.children?.map((cItem) => {
                      return (
                        <Link to={cItem.path} key={cItem.key}>
                          {cItem.label}
                        </Link>
                      );
                    })}
                  </Panel>
                </Collapse>
              );
            } else {
              return (
                <Link to={item.path} key={item.key} className='menuItem'>
                  {item.icon}
                  <span>{item.label}</span>
                </Link>
              );
            }
          })}

          {/* <div className='menuHead'>
            <span>Sales channels</span>
            <HiOutlineChevronRight />
          </div> */}

          {sideMenuItems2.map((item) => {
            if (item.hasOwnProperty("children")) {
              return (
                <Collapse
                  key={item.key}
                  className='collapseMenu'
                  onChange={() => {
                    navigate(item.path);
                  }}>
                  <Panel
                    className='collapseMenuPanel'
                    header={
                      <div className='collapseMenuItem'>
                        {item.icon}
                        <h3>{item.label}</h3>
                      </div>
                    }
                    key='1'>
                    {item?.children?.map((cItem) => {
                      return (
                        <Link to={cItem.path} key={cItem.key}>
                          {cItem.label}
                        </Link>
                      );
                    })}
                  </Panel>
                </Collapse>
              );
            } else {
              return (
                <Link to={item.path} key={item.key} className='menuItem'>
                  {item.icon}
                  <span>{item.label}</span>
                </Link>
              );
            }
          })}

          {/* <div className='menuHead'>
            <span>Apps</span>
            <HiOutlineChevronRight />
          </div> */}
        </div>

        {/* <Link to='/' className='menuItem settingsItem'>
          <svg
            width='21'
            height='20'
            viewBox='0 0 21 20'
            fill='none'
            className='menuIcon'
            xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M17.3502 9.94006C17.3502 9.93006 17.3502 9.91015 17.3502 9.90015C17.3502 9.52015 17.3102 9.14015 17.2502 8.78015L19.6602 7.21008L17.0802 3.25012L14.9202 4.66016C14.2702 4.10016 13.5102 3.67014 12.6902 3.39014V0.0800781H7.97024V3.49011C7.24024 3.77011 6.57023 4.18018 5.98023 4.68018L3.21023 2.45007L0.240234 6.13013L3.65023 8.88013C3.60023 9.22013 3.56023 9.57017 3.56023 9.92017C3.56023 10.1002 3.57023 10.2901 3.59023 10.4701L0.290236 12.6201L2.87024 16.5801L5.59023 14.8102C6.26023 15.4802 7.07024 16.0001 7.97024 16.3501V19.4401H12.6902V16.4501C13.7902 16.0701 14.7702 15.4201 15.5402 14.5801L17.4502 16.1201L20.4202 12.4401L17.3502 9.94006ZM10.3302 13.6501C8.17024 13.6501 6.42023 11.9001 6.42023 9.74011C6.42023 7.58011 8.17024 5.83008 10.3302 5.83008C12.4902 5.83008 14.2402 7.58011 14.2402 9.74011C14.2402 11.9001 12.4902 13.6501 10.3302 13.6501Z'
              fill='currentColor'
            />
          </svg>
          <span>Settings</span>
        </Link> */}
      </div>
    </>
  );
};

export default SidebarMenu;
