import { Layout, Checkbox, Select, Upload, Table, message, Button } from "antd";
import React, { useState } from "react";
import BreadCrumbs from "../../components/layout/BreadCrumbs";
import { BiLeftArrowAlt } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { BiDollar } from "react-icons/bi";
import { HiQuestionMarkCircle } from "react-icons/hi";
import { FiPlus } from "react-icons/fi";
import "./products.scss";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { FormError } from "../../components/custom/Custom";
import { useDispatch, useSelector } from "react-redux";
import { addProduct } from "../../redux/productSlice";
import { addProductSchema } from "../../utils/schema";
import { yupResolver } from "@hookform/resolvers/yup";

const { Content } = Layout;
const { Option } = Select;
const { Dragger } = Upload;

const AddProduct = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [costItemField, setCostItemField] = useState(false);
  const [trackQtyField, setTrackQtyField] = useState(false);
  const [continueOutOfStock, setContinueOutOfStock] = useState(false);
  const [shippingField, setShippingField] = useState(false);
  const [optionField, setOptionField] = useState(false);
  const [fileList, setFileList] = useState({});

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const store = useSelector((state) => state?.product);

  const props = {
    listType: "picture",
    accept: "image/png, image/jpg, image/jpeg",
    maxCount: 1,
    onRemove: () => {
      setFileList({});
    },

    beforeUpload: (file) => {
      setFileList(file);
      return false;
    },
  };

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      options: [{ name: "", value: "" }],
    },
    mode: "onChange",
    resolver: yupResolver(addProductSchema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "options",
  });

  // const onSelectChange = (newSelectedRowKeys) => {
  //   console.log("selectedRowKeys changed: ", selectedRowKeys);
  //   setSelectedRowKeys(newSelectedRowKeys);
  // };

  // const rowSelection = {
  //   selectedRowKeys,
  //   onChange: onSelectChange,
  // };

  // varient table columns and data
  const columns = [
    {
      title: "Variant",
      dataIndex: "variant",
    },
    {
      title: "Price",
      dataIndex: "price",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
    },
    // {
    //   title: "SKU",
    //   dataIndex: "sku",
    // },
  ];

  const data = [
    {
      key: 0,
      variant: `Small`,
      price: (
        <div className='priceFiled'>
          <div className='fieldWrapper'>
            <div className='field'>
              <BiDollar className='usdIcon' />
              <input
                type='number'
                min='0'
                placeholder='0.00'
                {...register(`sprice`)}
              />
            </div>
          </div>
          <FormError message={errors?.sprice?.message} />
        </div>
      ),
      quantity: (
        <div className='priceFiled'>
          <div className='fieldWrapper'>
            <div className='field'>
              <input
                type='number'
                min='0'
                placeholder='0'
                {...register(`squantity`)}
              />
            </div>
          </div>
          <FormError message={errors?.squantity?.message} />
        </div>
      ),
      // sku: (
      //   <button type='edit' className='editBtn'>
      //     Edit
      //   </button>
      // ),
    },
    {
      key: 1,
      variant: `Medium`,
      price: (
        <div className='priceFiled'>
          <div className='fieldWrapper'>
            <div className='field'>
              <BiDollar className='usdIcon' />
              <input
                type='number'
                min='0'
                placeholder='0.00'
                {...register(`mprice`)}
              />
            </div>
          </div>
          <FormError message={errors?.mprice?.message} />
        </div>
      ),
      quantity: (
        <div className='priceFiled'>
          <div className='fieldWrapper'>
            <div className='field'>
              <input
                type='number'
                min='0'
                placeholder='0'
                {...register(`mquantity`)}
              />
            </div>
          </div>
          <FormError message={errors?.mquantity?.message} />
        </div>
      ),
      // sku: (
      //   <button type='edit' className='editBtn'>
      //     Edit
      //   </button>
      // ),
    },
  ];

  // submit form
  const onFormSubmit = (values) => {
    const formData = new FormData();
    formData.append("image", fileList);

    const {
      title,
      weight,
      price,
      compareAt,
      costPerItem,
      stockKeepingUnit,
      barcode,
      location,
      available,
      options,
      sprice,
      squantity,
      mprice,
      mquantity,
      status,
    } = values;
    const data = {
      frameTitle: title,
      status: status,
      weight: shippingField ? weight : "0",
      shipping: shippingField,
      pricing: {
        price: Number(price),
        compareAtPrice: Number(compareAt),
        costPerItem: costItemField ? costPerItem : 0,
        chargeTax: costItemField,
      },
      inventory: {
        stockKeepingUnit: Number(stockKeepingUnit),
        barcode: barcode,
        continueSellingWhenOutOfStock: continueOutOfStock,
        trackQuantity: trackQtyField,
        quantity: {
          locationName: location ? location : "N/A",
          available: available ? Number(available) : 0,
        },
      },
      option: {
        haveOptions: optionField,
        data: optionField ? options : [],
      },
      variant: {
        small: {
          price: Number(sprice),
          quantity: Number(squantity),
          SKU: "lasdf",
        },
        medium: {
          price: Number(mprice),
          quantity: Number(mquantity),
          SKU: "lasdf0",
        },
      },
    };

    dispatch(addProduct({ data, formData, navigate }));
  };
  return (
    <>
      <div className='addProductContainer'>
        <div className='addProduct'>
          <div className='pageHeader'>
            <BiLeftArrowAlt
              className='backBtn'
              onClick={() => {
                navigate("/products");
              }}
            />
            <BreadCrumbs title='Add product' />
          </div>
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <Content className='addProductContent'>
              {/* right block */}
              <div className='rightBlock'>
                <div className='productDetail'>
                  <div className='title'>
                    <label htmlFor=''>Title</label>
                    <div className='titleFiled'>
                      <input
                        type='text'
                        placeholder='Golden Frame'
                        name='title'
                        {...register("title", {
                          required: "title is required",
                        })}
                      />
                      <img src='/assets/icons/addressCard.svg' />
                    </div>
                    <FormError message={errors?.title?.message} />
                  </div>

                  <div className='media'>
                    <label>Media</label>

                    <Controller
                      control={control}
                      name='image'
                      rules={{ required: "image is required" }}
                      render={({ field }) => (
                        <Dragger
                          {...props}
                          onChange={(e) => {
                            field.onChange(e.fileList);
                          }}>
                          <p className='ant-upload-text'>+ Add file</p>
                        </Dragger>
                      )}
                    />

                    <FormError message={errors?.image?.message} />
                  </div>
                </div>

                <div className='pricingContainer'>
                  <h3>Pricing</h3>

                  <div className='pricing'>
                    <div className='priceFiled'>
                      <label>Price</label>
                      <div className='fieldWrapper'>
                        <div className='field'>
                          <BiDollar className='usdIcon' />
                          <input
                            type='number'
                            placeholder='0.00'
                            min='0'
                            {...register("price", {
                              required: "price is required",
                            })}
                          />
                        </div>
                      </div>
                      <FormError message={errors?.price?.message} />
                    </div>
                    <div className='priceFiled'>
                      <label>Compare at price</label>
                      <div className='fieldWrapper'>
                        <div className='field'>
                          <BiDollar className='usdIcon' />
                          <input
                            type='number'
                            placeholder='0.00'
                            min='0'
                            {...register("compareAt", {
                              required: "compare at is required",
                            })}
                          />
                          <HiQuestionMarkCircle className='usdIcon' />
                        </div>
                      </div>
                      <FormError message={errors?.compareAt?.message} />
                    </div>
                  </div>

                  <Controller
                    control={control}
                    name='chargeTax'
                    render={({ field }) => (
                      <Checkbox
                        className='tax'
                        onChange={(e) => {
                          field.onChange(e.target.checked);
                          setCostItemField(e.target.checked);
                        }}>
                        Charge tax on this product
                      </Checkbox>
                    )}
                  />

                  {costItemField && (
                    <div className='cost'>
                      <div className='costFiled'>
                        <label>Cost per item</label>
                        <div className='fieldWrapper'>
                          <div className='field'>
                            <BiDollar className='usdIcon' />
                            <input
                              type='number'
                              min='0'
                              placeholder='0.00'
                              {...register(`costPerItem`)}
                            />
                          </div>
                        </div>
                        <FormError message={errors?.costPerItem?.message} />
                      </div>
                    </div>
                  )}
                </div>

                <div className='inventoryContainer'>
                  <h3>Inventory</h3>
                  <div className='inventory'>
                    <div className='inventoryFiled'>
                      <label>SKU (Stock Keeping Unit)</label>
                      <div className='fieldWrapper'>
                        <div className='field'>
                          <input
                            type='number'
                            min='0'
                            {...register(`stockKeepingUnit`)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='inventoryFiled'>
                      <label>Barcode (ISBN, UPC, GTIN, etc.) </label>
                      <div className='fieldWrapper'>
                        <div className='field'>
                          <input
                            type='text'
                            {...register("barcode", {
                              required: "barcode is required ",
                            })}
                          />
                        </div>
                      </div>
                      <FormError message={errors?.barcode?.message} />
                    </div>
                  </div>
                  <div className='quantityCheckbox'>
                    <Controller
                      control={control}
                      name='trackQuantity'
                      render={({ field }) => (
                        <Checkbox
                          className='qty'
                          onChange={(e) => {
                            field.onChange(e.target.checked);
                            setTrackQtyField(e.target.checked);
                          }}>
                          Track quantity
                        </Checkbox>
                      )}
                    />

                    <Checkbox
                      className='stock'
                      onChange={(e) => setContinueOutOfStock(e.target.checked)}>
                      Continue selling when out of stock
                    </Checkbox>
                  </div>
                  {trackQtyField && (
                    <div className='quantity'>
                      <h3>Quantity</h3>
                      <div className='header'>
                        <h3>Location name</h3>
                        <h3>Available</h3>
                      </div>
                      <div className='body'>
                        <div className='priceFiled'>
                          <div className='fieldWrapper'>
                            <div className='field'>
                              <input
                                type='text'
                                placeholder='123 Market Street'
                                {...register("location", {
                                  required: "location is required ",
                                })}
                              />
                            </div>
                          </div>
                          <FormError message={errors?.location?.message} />
                        </div>
                        <div className='priceFiled'>
                          <div className='fieldWrapper'>
                            <div className='field'>
                              <input
                                type='number'
                                placeholder='0'
                                min='0'
                                {...register("available", {
                                  required: "available is required ",
                                })}
                              />
                            </div>
                          </div>
                          <FormError message={errors?.available?.message} />
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className='shippingContainer'>
                  <h3>Shipping</h3>

                  <div className='shippingCheckbox'>
                    <Controller
                      control={control}
                      name='shippingCheckbox'
                      render={({ field }) => (
                        <Checkbox
                          className='checkBox'
                          onChange={(e) => {
                            field.onChange(e.target.checked);
                            setShippingField(e.target.checked);
                          }}>
                          This is a physical product
                        </Checkbox>
                      )}
                    />
                  </div>

                  {shippingField && (
                    <div className='weight'>
                      <h3>Weight</h3>

                      <div className='body'>
                        <div className='weightFiledWrapper'>
                          <div className='weightFiled'>
                            <label>Weight</label>
                            <div className='fieldWrapper'>
                              <div className='field'>
                                <input
                                  type='number'
                                  min='0'
                                  {...register("weight", {
                                    required: "weight is required ",
                                  })}
                                />
                              </div>
                            </div>
                            <FormError message={errors?.weight?.message} />
                          </div>

                          <div className='unit'>
                            <Select
                              defaultValue='lb'
                              suffixIcon={
                                <img src='/assets/icons/selectArrows.svg' />
                              }>
                              <Option value='lb'>lb</Option>
                            </Select>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className='optionContainer'>
                  <h3>Options</h3>

                  <div className='optionCheckbox'>
                    <Controller
                      control={control}
                      name='optionCheckbox'
                      render={({ field }) => (
                        <Checkbox
                          className='checkBox'
                          onChange={(e) => {
                            field.onChange(e.target.checked);
                            setOptionField(e.target.checked);
                          }}>
                          This product has options, like size or color
                        </Checkbox>
                      )}
                    />
                  </div>
                  {optionField && (
                    <>
                      <div className='option'>
                        <div className='body'>
                          {fields.map((field, index, fields) => {
                            return (
                              <div key={field.id} className='optionWrapper'>
                                <div className='optionFiledWrapper'>
                                  <div className='optionFiled'>
                                    <label>Option name</label>
                                    <input
                                      type='text'
                                      {...register(`options.${index}.name`, {
                                        required: "name is required ",
                                      })}
                                    />
                                    <FormError
                                      message={
                                        errors?.options?.[index]?.name?.message
                                      }
                                    />
                                  </div>

                                  <div
                                    className='delete'
                                    onClick={() =>
                                      fields.length > 1 ? remove(index) : ""
                                    }>
                                    <img src='/assets/icons/trash.svg' />
                                  </div>
                                </div>

                                <div className='optionFiledWrapper'>
                                  <div className='optionFiled'>
                                    <label>Option value</label>
                                    <input
                                      type='text'
                                      {...register(`options.${index}.value`, {
                                        required: "value is required ",
                                      })}
                                    />
                                    <FormError
                                      message={
                                        errors?.options?.[index]?.value?.message
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            );
                          })}

                          {/* <button type='button'>Done</button> */}
                        </div>
                      </div>

                      <div className='addOption'>
                        <button
                          type='button'
                          onClick={() => {
                            append({ name: "", value: "" });
                          }}>
                          <FiPlus className='addIcon' />
                          <span> Add another option</span>
                        </button>
                      </div>
                    </>
                  )}
                </div>

                <div className='variantContainer'>
                  <h3>Variants</h3>

                  <Table
                    // rowSelection={rowSelection}
                    columns={columns}
                    dataSource={data}
                    pagination={false}
                    className='variantTable'
                  />
                </div>
              </div>

              {/* left block */}
              <div className='leftBlock'>
                <div className='notes'>
                  <h3>Product status</h3>

                  <Controller
                    control={control}
                    name='status'
                    rules={{ required: "status is required" }}
                    render={({ field }) => (
                      <Select
                        name='status'
                        placeholder='Select Status'
                        onChange={(value) => {
                          field.onChange(value);
                        }}
                        suffixIcon={
                          <img src='/assets/icons/selectArrows.svg' />
                        }>
                        <Option value='OPEN'>Open</Option>
                        <Option value='CLOSED'>Closed</Option>
                      </Select>
                    )}
                  />
                  <FormError message={errors?.status?.message} />
                </div>
              </div>
            </Content>
            <div className='pageFooter'>
              <button type='submit'>
                {store?.loading ? "Loading..." : "Save"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddProduct;
