import { APIurls } from "./constants";
import apiService, { fetchService } from ".";

export const ApiRequests = {
  // signin
  signin: async (data) => await apiService.post(APIurls.signin, data),

  adminProfile: async () => await apiService.get(APIurls.adminProfile),

  //orders
  getAllOrders: async () => await apiService.get(APIurls.getAllOrders),

  getAllOrdersCanada: async () =>
    await apiService.get(APIurls.getAllOrdersCanada),

  getCompletedOrders: async () =>
    await apiService.get(APIurls.getCompletedOrders),

  getPendingOrders: async () => await apiService.get(APIurls.getPendingOrders),

  getCanceledOrders: async () =>
    await apiService.get(APIurls.getCanceledOrders),

  getSingleOrder: async (id) =>
    await apiService.get(APIurls.getSingleOrder + "/" + id),

  updateOrder: async (orderID, data) =>
    await apiService.patch(APIurls.updateOrder + "/" + orderID, data),

  // giftcard
  getAllGiftCard: async () => await apiService.get(APIurls.giftcard),

  getSingleGiftCard: async (id) =>
    await apiService.get(APIurls.SingleGiftcard + "/" + id),

  issueGiftCard: async (data) =>
    await apiService.post(APIurls.issueGiftCard, data),

  addGiftCard: async (data) =>
    await fetchService(APIurls.addGiftCard, "POST", data),

  updateGiftCard: async (giftCardID, data) =>
    await apiService.put(APIurls.updateGiftCard + "/" + giftCardID, data),

  updateGiftCardImage: async (giftCardID, image) =>
    await fetchService(
      APIurls.updateGiftCardImage + "/" + giftCardID,
      "PATCH",
      image
    ),

  deleteGiftCard: async (id) =>
    await apiService.delete(APIurls.deleteGiftCard + "/" + id),

  giftCardSearch: async (data) =>
    await apiService.post(APIurls.giftCardSearch, data),

  // products
  getAllProducts: async () => await apiService.get(APIurls.getAllProducts),

  getActiveProducts: async () =>
    await apiService.get(APIurls.getActiveProducts),

  getClosedProducts: async () =>
    await apiService.get(APIurls.getClosedProducts),

  getArchivedProducts: async () =>
    await apiService.get(APIurls.getArchivedProducts),

  getSingleProducts: async (id) =>
    await apiService.get(APIurls.getSingleProducts + "/" + id),

  addProduct: async (data) => await apiService.post(APIurls.addProduct, data),

  updateProduct: async (productID, data) =>
    await apiService.put(APIurls.updateProduct + "/" + productID, data),

  updateProductImage: async (productID, image) =>
    await fetchService(
      APIurls.updateProductImage + "/" + productID,
      "PATCH",
      image
    ),

  deleteProduct: async (id) =>
    await apiService.delete(APIurls.deleteProduct + "/" + id),

  productSearch: async (data) =>
    await apiService.post(APIurls.productSearch, data),

  // off products discount
  getAllAmountOffProduct: async () =>
    await apiService.get(APIurls.getAllAmountOffProduct),

  addAmountOffProduct: async (data) =>
    await apiService.post(APIurls.addAmountOffProduct, data),

  deleteAmountOffProduct: async (id) =>
    await apiService.delete(APIurls.deleteAmountOffProduct + "/" + id),

  // off order discount

  getAllAmountOffOrder: async () =>
    await apiService.get(APIurls.getAllAmountOffOrder),

  addAmountOffOrder: async (data) =>
    await apiService.post(APIurls.addAmountOffOrder, data),

  deleteAmountOffOrder: async (id) =>
    await apiService.delete(APIurls.deleteAmountOffOrder + "/" + id),

  // buy x get y

  getAllBuyXgetY: async () => await apiService.get(APIurls.getAllBuyXgetY),

  addBuyXgetY: async (data) => await apiService.post(APIurls.addBuyXgetY, data),

  deleteBuyXgetY: async (id) =>
    await apiService.delete(APIurls.deleteBuyXgetY + "/" + id),

  // free shipping discount
  getAllFreeShipping: async () =>
    await apiService.get(APIurls.getAllFreeShipping),

  addFreeShipping: async (data) =>
    await apiService.post(APIurls.addFreeShipping, data),

  deleteFreeShipping: async (id) =>
    await apiService.delete(APIurls.deleteFreeShipping + "/" + id),

  //cutomers
  getAllCustomer: async () => await apiService.get(APIurls.getAllCustomer),
  customerSearch: async (data) =>
    await apiService.post(APIurls.customerSearch, data),
};
