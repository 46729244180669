import React, { useEffect } from "react";
import { Layout, Tabs } from "antd";
import BreadCrumbs from "../../components/layout/BreadCrumbs";
import { useNavigate } from "react-router-dom";
import All from "../../components/products/tabs/All";
import Active from "../../components/products/tabs/Active";
import Closed from "../../components/products/tabs/Closed";
import Archived from "../../components/products/tabs/Archived";
import "./products.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllProducts,
  getActiveProducts,
  getClosedProducts,
  getArchivedProducts,
} from "../../redux/productSlice";
import GiftCards from "../../components/products/tabs/GiftCards";
import { getAllGiftCard } from "../../redux/giftCardSlice";

const { Content } = Layout;

const Products = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const products = store?.product?.products;
  const active = store?.product?.activeProducts;
  const closed = store?.product?.closedProducts;
  const archived = store?.product?.archivedProducts;
  const loading = store?.product?.loading;
  const giftCardProduct = store?.giftCard?.giftCards;
  const giftloading = store?.giftCard?.loading;

  useEffect(() => {
    dispatch(getAllProducts());
    dispatch(getActiveProducts());
    dispatch(getClosedProducts());
    dispatch(getArchivedProducts());
    dispatch(getAllGiftCard());
  }, []);

  return (
    <>
      <div className='pageHeader'>
        <BreadCrumbs title='Products' />
        <div className='pageHeaderAction'>
          {/* <button type='button' className='exportbtn'>
            Export
          </button> */}
          <button
            type='button'
            className='addbtn'
            onClick={() => {
              navigate("/add-product");
            }}>
            Add products
          </button>
        </div>
      </div>
      <Content className='products'>
        <Tabs
          defaultActiveKey='1'
          items={[
            {
              label: `All`,
              key: "1",
              children: <All allProducts={products} loading={loading} />,
            },
            {
              label: `Active`,
              key: "2",
              children: <Active allProducts={active} loading={loading} />,
            },
            {
              label: `Closed`,
              key: "3",
              children: <Closed allProducts={closed} loading={loading} />,
            },
            {
              label: `Archived`,
              key: "4",
              children: <Archived allProducts={archived} loading={loading} />,
            },
            {
              label: `Gift Cards`,
              key: "6",
              children: (
                <GiftCards
                  allProducts={giftCardProduct}
                  loading={giftloading}
                />
              ),
            },
          ]}
        />
      </Content>
    </>
  );
};

export default Products;
