import React from "react";

const BreadCrumbs = ({ title }) => {
  return (
    <div className='breadCrumbContainer'>
      <h2 className='breadCrumb'>{title}</h2>
    </div>
  );
};

export default BreadCrumbs;
