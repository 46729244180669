import { DatePicker, Layout, Radio } from "antd";
import "./giftcard.scss";
import React, { useState } from "react";
import BreadCrumbs from "../../components/layout/BreadCrumbs";
import { BiLeftArrowAlt } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { IoSearch } from "react-icons/io5";
import { BsCurrencyDollar } from "react-icons/bs";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { issueGiftCard } from "../../redux/giftCardSlice";
import { FormError } from "../../components/custom/Custom";

const { Content } = Layout;

const IssueGiftCards = () => {
  const [showDateField, setShowDateField] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();

  const store = useSelector((state) => state?.giftCard);

  const onFormSubmit = (values) => {
    const data = {
      code: values.giftCardCode,
      amount: values.initilValue,
      expiryDate: values.expiryDate,
      notes: values.notes,
      email: values.email,
    };
    dispatch(issueGiftCard({ data, reset, setShowDateField, navigate }));
  };
  return (
    <>
      <div className='issueGiftCardContainer'>
        <div className='issueGiftCard'>
          <div className='pageHeader'>
            <BiLeftArrowAlt
              className='backBtn'
              onClick={() => {
                navigate("/gift-cards");
              }}
            />
            <BreadCrumbs title='Issue gift card' />
          </div>
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <Content className='issueGiftCardContent'>
              {/* right block */}
              <div className='rightBlock'>
                <div className='giftCardDetail'>
                  <h3>Gift card details</h3>

                  <div className='giftCardCode'>
                    <label htmlFor=''>Gift card code</label>
                    <input
                      type='text'
                      placeholder='f39vrxg7kc938yvw'
                      {...register("giftCardCode", {
                        required: "gift card code is required",
                      })}
                    />
                    <FormError message={errors?.giftCardCode?.message} />
                  </div>
                  <div className='initilValue'>
                    <label htmlFor=''>Initial value</label>
                    <div className='initilValueFiled'>
                      <BsCurrencyDollar className='usdIcon' />
                      <input
                        type='number'
                        min='0'
                        placeholder='10.00'
                        {...register("initilValue", {
                          required: "initil value is required",
                        })}
                      />
                    </div>
                    <FormError message={errors?.initilValue?.message} />
                  </div>
                </div>
                <div className='expirationDate'>
                  <h3>Expiration date</h3>
                  <p>
                    Countries have different laws for gift card expiry dates.
                    Check the laws for your country before changing this date.
                  </p>
                  <div className='expiration'>
                    <div className='minimumRequired'>
                      <h3>Minimum purchase requirements</h3>
                      <Radio.Group
                        className='radioBtn'
                        value={showDateField}
                        onChange={(e) => {
                          setShowDateField(e.target.value);
                        }}>
                        <Radio value={false}>No expiration date</Radio>
                        <Radio value={true}>Set expiration date</Radio>
                      </Radio.Group>
                    </div>

                    {showDateField && (
                      <div className='dateFiledWrapper'>
                        <div className='filed'>
                          <img src='/assets/icons/calendar.svg' />

                          <Controller
                            control={control}
                            name='expiryDate'
                            rules={{ required: "expiry date is required" }}
                            render={({ field }) => (
                              <DatePicker
                                onChange={(_, dateString) =>
                                  field.onChange(dateString)
                                }
                                selected={field.value}
                                bordered={false}
                                suffixIcon={false}
                              />
                            )}
                          />
                        </div>
                        <FormError message={errors?.expiryDate?.message} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* left block */}
              <div className='leftBlock'>
                <div className='findCustomer'>
                  <h3>Find or create a customer</h3>
                  <p>
                    To send the gift card code, add a customer with an email
                    address or phone number.
                  </p>
                  <div className='findCustomerSearch'>
                    <IoSearch className='searchIcon' />
                    <input
                      type='text'
                      placeholder='Customer Email'
                      {...register("email", {
                        required: "email is required",
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: "invalid email address",
                        },
                      })}
                    />
                  </div>
                  <FormError message={errors?.email?.message} />
                </div>
                <div className='notes'>
                  <h3>Notes</h3>
                  <input
                    type='text'
                    placeholder='Add a note'
                    {...register("notes")}
                  />
                  <p>
                    These notes are private and won’t be shared with the
                    customer.
                  </p>
                </div>
              </div>
            </Content>
            <div className='pageFooter'>
              <button type='submit'>
                {store?.loading ? "Loading..." : "Activate"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default IssueGiftCards;
