import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiRequests } from "../services/ApiRequest";
import { toast } from "react-toastify";

//get all Customers
export const getAllCustomer = createAsyncThunk(
  "customer/getAllCustomer",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.getAllCustomer();
      return response?.data;
    } catch (error) {
      console.log(error);
    }
  }
);

//get all Customers
export const customerSearch = createAsyncThunk(
  "customer/customerSearch",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.customerSearch(data);
      return response?.data;
    } catch (error) {
      console.log(error);
    }
  }
);

// delete Customer
export const deleteCustomer = createAsyncThunk(
  "customer/deleteCustomer",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.deleteCustomer(data);
      console.log(response);
      toast.success("Customer deleted Successfully!", {
        position: "top-right",
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "light",
      });
    } catch (error) {
      console.log(error);
    }
  }
);

const initialState = {
  customers: [],
  searchedCustomers: [],
  loading: false,
};
const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllCustomer.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllCustomer.fulfilled, (state, action) => {
        state.customers = action.payload;
        state.loading = false;
      })

      .addCase(customerSearch.pending, (state) => {
        state.loading = true;
      })
      .addCase(customerSearch.fulfilled, (state, action) => {
        state.searchedCustomers = action.payload;
        state.loading = false;
      })

      .addCase(deleteCustomer.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteCustomer.fulfilled, (state) => {
        state.loading = false;
      });
  },
});

export default customerSlice.reducer;
