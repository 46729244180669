import { Layout, Select, Upload, message } from "antd";
import React, { useState } from "react";
import BreadCrumbs from "../../components/layout/BreadCrumbs";
import { BiLeftArrowAlt } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import TextEditor from "../../components/custom/TextEditor";
import { BiDollar } from "react-icons/bi";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { stateToHTML } from "draft-js-export-html";
import { useDispatch, useSelector } from "react-redux";
import { addGiftCard } from "../../redux/giftCardSlice";
import { FormError } from "../../components/custom/Custom";
import { yupResolver } from "@hookform/resolvers/yup";
import "./giftcard.scss";
import { addGiftCardSchema } from "../../utils/schema";

const { Content } = Layout;
const { Option } = Select;
const { Dragger } = Upload;

const AddGiftCard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [fileList, setFileList] = useState([]);
  const [editorState, setEditorState] = useState("");

  const store = useSelector((state) => state?.giftCard);
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      denomination: [{ denomination: "" }],
    },
    mode: "onChange",
    resolver: yupResolver(addGiftCardSchema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "denomination",
  });

  const props = {
    listType: "picture",
    accept: "image/png, image/jpg, image/jpeg",
    maxCount: 3,
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },

    beforeUpload: (file) => {
      setFileList([...fileList, file]);
      return false;
    },
    // fileList,
  };

  const onFormSubmit = (data) => {
    let html =
      editorState === ""
        ? "<p>No Description</p>"
        : stateToHTML(editorState.getCurrentContent());
    const formData = new FormData();

    formData.append("giftCardTitle", data?.pictingGiftCard);
    formData.append("giftCardDescription", html);
    data?.denomination.map((item) => {
      formData.append("denominations", item.denomination);
    });
    fileList.map((file) => {
      formData.append("image", file);
    });
    formData.append("status", data.status);

    dispatch(addGiftCard({ formData, navigate }));
  };

  return (
    <>
      <div className='addGiftCardContainer'>
        <div className='addGiftCard'>
          <div className='pageHeader'>
            <BiLeftArrowAlt
              className='backBtn'
              onClick={() => {
                navigate("/gift-cards");
              }}
            />
            <BreadCrumbs title='Create gift card product' />
          </div>
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <Content className='addGiftCardContent'>
              {/* right block */}
              <div className='rightBlock'>
                <div className='giftCardDetail'>
                  <div className='title'>
                    <label htmlFor=''>Title</label>
                    <div className='titleFiled'>
                      <input
                        type='text'
                        name='pictingGiftCard'
                        placeholder='Pictings Gift Card'
                        {...register("pictingGiftCard", {
                          required: "title is required",
                        })}
                      />
                      <img src='/assets/icons/addressCard.svg' />
                    </div>
                    <FormError message={errors?.pictingGiftCard?.message} />
                  </div>

                  <div className='description'>
                    <label htmlFor=''>Description</label>
                    <TextEditor
                      editorState={editorState}
                      setEditorState={setEditorState}
                    />
                  </div>
                </div>
                <div className='media'>
                  <h3>Media</h3>

                  <Controller
                    control={control}
                    name='image'
                    rules={{ required: "image is required" }}
                    render={({ field }) => (
                      <Dragger
                        {...props}
                        onChange={(e) => field.onChange(e.fileList)}>
                        <p className='ant-upload-text'>+ Add file</p>
                      </Dragger>
                    )}
                  />

                  <FormError message={errors?.image?.message} />
                </div>

                <div className='denominations'>
                  <h3>Denominations</h3>
                  {fields.map((field, index, fields) => {
                    return (
                      <div key={field.id} className='denominationFiled'>
                        <div style={{ width: "100%" }}>
                          <div className='field'>
                            <BiDollar className='usdIcon' />
                            <input
                              type='number'
                              min='0'
                              placeholder='10.00'
                              {...register(
                                `denomination.${index}.denomination`,
                                {
                                  required: "denomination is required",
                                }
                              )}
                            />
                          </div>
                          <FormError
                            message={
                              errors?.denomination?.[index]?.denomination
                                ?.message
                            }
                          />
                        </div>

                        <img
                          src='/assets/icons/trash.svg'
                          className='trashIcon'
                          onClick={() =>
                            fields.length > 1 ? remove(index) : ""
                          }
                        />
                      </div>
                    );
                  })}

                  <button
                    type='button'
                    onClick={() => {
                      append({
                        denomination: "",
                      });
                    }}>
                    Add denomination
                  </button>
                </div>
              </div>
              {/* left block */}
              <div className='leftBlock'>
                <div className='notes'>
                  <h3>Product status</h3>
                  <Controller
                    control={control}
                    name='status'
                    rules={{ required: "status is required" }}
                    render={({ field }) => (
                      <Select
                        name='status'
                        placeholder='Select Status'
                        onChange={(value) => {
                          field.onChange(value);
                        }}
                        suffixIcon={
                          <img src='/assets/icons/selectArrows.svg' />
                        }>
                        <Option value='true'>Active</Option>
                        <Option value='false'>Deactive</Option>
                      </Select>
                    )}
                  />
                  <FormError message={errors?.status?.message} />
                </div>
              </div>
            </Content>
            <div className='pageFooter'>
              <button type='submit'>
                {store?.loading ? "Loading..." : " Save gift card product"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddGiftCard;
