import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getSingleGiftCard } from "../../redux/giftCardSlice";

const SingleGiftcard = () => {
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    dispatch(getSingleGiftCard(params.id));
  }, []);

  return <div>{params.id}</div>;
};

export default SingleGiftcard;
