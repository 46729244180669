import React, { useEffect, useState } from "react";
import { Table, Modal } from "antd";
import { IoSearch } from "react-icons/io5";
import { FiEdit, FiTrash } from "react-icons/fi";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { deleteProduct } from "../../../redux/productSlice";
import { useDispatch, useSelector } from "react-redux";
import { productSearch } from "../../../redux/productSlice";

const Active = ({ allProducts, loading }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [products, setProducts] = useState(allProducts);
  const [searchValue, setSearchValue] = useState("");

  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const searchedProducts = store?.product?.searchedProducts;

  useEffect(() => {
    if (searchValue !== "") {
      setProducts(searchedProducts);
    } else {
      setProducts(allProducts);
    }
  }, [allProducts, searchedProducts]);

  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const confirm = (id) => {
    Modal.confirm({
      title: "Are you sure!",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      async onOk() {
        await dispatch(deleteProduct(id));
      },
      cancelText: "No",
    });
  };

  const columns = [
    {
      title: "Product",
      dataIndex: "product",
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Inventory",
      dataIndex: "inventory",
    },
    {
      title: "",
      dataIndex: "action",
    },
  ];
  const data = [];

  products.map((product) => {
    data.push({
      key: product?._id,
      product: (
        <div className='productDetail'>
          {product?.frame !== "NULL" ? (
            <img src={product?.frame} className='productImg' />
          ) : (
            <div className='productImgPlaceholder'></div>
          )}

          <Link to={`/products/${product?._id}`}>
            <span className='productName'>{product?.frameTitle}</span>
          </Link>
        </div>
      ),
      status:
        product?.status == "OPEN" ? (
          <p className='successBadge'>{product?.status}</p>
        ) : (
          <p className='dangerBadge'>{product?.status}</p>
        ),
      inventory: `${
        product?.variant?.small?.quantity +
          product?.variant?.medium?.quantity || 0
      } in stock`,
      action: (
        <div className='action'>
          <Link to={`/products/${product?._id}/edit`}>
            <FiEdit
              className='actionBtn'
              // onClick={() => {
              //   Modal.info({
              //     title: "This feature is under development",
              //     onOk() {},
              //   });
              // }}
            />
          </Link>
          <FiTrash
            className='actionBtn'
            onClick={() => confirm(product?._id)}
          />
        </div>
      ),
    });
  });

  return (
    <>
      <div className='productsTableContainer'>
        <div className='productsTableHeader'>
          <div className='productsSearch'>
            <IoSearch className='searchIcon' />
            <input
              type='search'
              placeholder='Search products'
              onChange={(e) => {
                setSearchValue(e.target.value);
                dispatch(
                  productSearch({
                    name: e.target.value,
                  })
                );
                // setProducts(
                //   allProducts.filter((product) =>
                //     String(product?.frameTitle)
                //       .toUpperCase()
                //       .includes(String(e.target.value).toUpperCase())
                //   )
                // );
              }}
            />
          </div>
        </div>

        <Table
          className='customerTable'
          rowSelection={rowSelection}
          loading={loading}
          columns={columns}
          dataSource={data}
          pagination={{
            defaultPageSize: 7,
            showSizeChanger: false,
            hideOnSinglePage: true,
          }}
        />
      </div>
    </>
  );
};

export default Active;
