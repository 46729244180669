import React, { useEffect } from "react";
import "./auth.scss";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { signin } from "../../redux/authSlice";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const store = useSelector((state) => state?.auth);

  useEffect(() => {
    const authUser = JSON.parse(localStorage.getItem("authUser"));

    if (authUser?.token) {
      navigate("/");
    }
  }, [navigate]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onFormSubmit = (data) => {
    dispatch(signin({ data, navigate }));
  };

  return (
    <div className='authWrapper'>
      <div className='authFormBox'>
        <div className='logo'>
          <img src='./assets/images/logo.png' />
        </div>

        <form onSubmit={handleSubmit(onFormSubmit)}>
          <div className='formField'>
            <label htmlFor=''>Email</label>
            <input type='email' placeholder='Email' {...register("email")} />
          </div>
          <div className='formField'>
            <label htmlFor=''>Password</label>
            <input
              type='password'
              placeholder='Password'
              {...register("password")}
            />
          </div>
          <p className='error'>{store?.error}</p>
          <button type='submit'>
            {store?.loading ? "loading..." : "Login"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
