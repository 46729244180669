import React from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const TextEditor = ({ editorState, setEditorState }) => {
  const onChange = async (value) => {
    setEditorState(value);
  };
  return (
    <>
      <Editor
        editorState={editorState}
        toolbarClassName='editor-toolbar'
        wrapperClassName='editor-wrapper'
        editorClassName='textEditor'
        onEditorStateChange={(value) => {
          onChange(value);
        }}
        // stripPastedStyles
        // ariaLabel='draftEditor'
      />
    </>
  );
};

export default TextEditor;
