import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiRequests } from "../services/ApiRequest";
import { toast } from "react-toastify";

//get all amount off product discount
export const getAllAmountOffProduct = createAsyncThunk(
  "amountOffProduct/getAllAmountOffProduct",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.getAllAmountOffProduct();
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  }
);

// add amount off product discount
export const addAmountOffProduct = createAsyncThunk(
  "amountOffProduct/addAmountOffProduct",
  async ({ data, navigate }, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.addAmountOffProduct(data);
      navigate("/discount");

      toast.success("Discount is added successfully!", {
        position: "top-right",
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "light",
      });
    } catch (error) {
      toast.error(error?.response?.data?.msg, {
        position: "top-right",
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "light",
      });
    }
  }
);

// delete Amount Off product
export const deleteAmountOffProduct = createAsyncThunk(
  "amountOffProduct/deleteAmountOffProduct",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.deleteAmountOffProduct(data);
      console.log(response);
      toast.success("Amount Off Product discount deleted Successfully!", {
        position: "top-right",
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "light",
      });
    } catch (error) {
      console.log(error);
    }
  }
);

const initialState = {
  amountOffProduct: [],
  loading: false,
};
const amountOffProductSlice = createSlice({
  name: "amountOffProduct",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllAmountOffProduct.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllAmountOffProduct.fulfilled, (state, action) => {
        state.amountOffDiscount = action.payload;
        state.loading = false;
      })

      .addCase(addAmountOffProduct.pending, (state) => {
        state.loading = true;
      })
      .addCase(addAmountOffProduct.fulfilled, (state) => {
        state.loading = false;
      })

      .addCase(deleteAmountOffProduct.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteAmountOffProduct.fulfilled, (state) => {
        state.loading = false;
      });
  },
});

export default amountOffProductSlice.reducer;
