import React from "react";
import { Avatar, Layout, Menu, Dropdown } from "antd";
import { IoSearchSharp } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";
import { logout } from "../../redux/authSlice";
import { useDispatch, useSelector } from "react-redux";

const { Header } = Layout;

const LayoutHeader = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const store = useSelector((state) => state?.auth);
  const admin = store?.profile;

  const menu = (
    <Menu
      items={[
        {
          key: "1",
          label: (
            <p
              onClick={() => {
                localStorage.removeItem("authUser");
                dispatch(logout());
                navigate("/login");
              }}>
              Logout
            </p>
          ),
        },
      ]}
    />
  );
  return (
    <>
      {location?.pathname == "/add-gift-cards" ? (
        <Header className='darkHeader'>
          <div>
            <img src='/assets/images/logo.png' className='logo' />
          </div>
          <p>Unsaved product</p>
          <div className='actionBtns'>
            {/* <button className='discard'>Discard</button>
            <button className='save'>Save</button> */}
          </div>
        </Header>
      ) : location?.pathname == "/add-product" ? (
        <Header className='darkHeader'>
          <div>
            <img src='/assets/images/logo.png' className='logo' />
          </div>
          <p>Unsaved product</p>
          <div className='actionBtns'></div>
        </Header>
      ) : location?.pathname == "/discount/amount-off-product" ? (
        <Header className='darkHeader'>
          <div>
            <img src='/assets/images/logo.png' className='logo' />
          </div>
          <p>Unsaved discount</p>
          <div className='actionBtns'>
            {/* <button className='discard'>Discard</button>
            <button className='save'>Save</button> */}
          </div>
        </Header>
      ) : location?.pathname == "/discount/amount-off-order" ? (
        <Header className='darkHeader'>
          <div>
            <img src='/assets/images/logo.png' className='logo' />
          </div>
          <p>Unsaved discount</p>
          <div className='actionBtns'>
            {/* <button className='discard'>Discard</button>
            <button className='save'>Save</button> */}
          </div>
        </Header>
      ) : location?.pathname == "/discount/buy-x-get-y" ? (
        <Header className='darkHeader'>
          <div>
            <img src='/assets/images/logo.png' className='logo' />
          </div>
          <p>Unsaved discount</p>
          <div className='actionBtns'>
            {/* <button className='guid'>
              <img src='/assets/icons/flag.svg' />
              <span>Setup guide</span>
            </button>
            <button className='discard'>Discard</button>
            <button className='save'>Save</button> */}
          </div>
        </Header>
      ) : location?.pathname == "/discount/free-shipping" ? (
        <Header className='darkHeader'>
          <div>
            <img src='/assets/images/logo.png' className='logo' />
          </div>
          <p>Unsaved discount</p>
          <div className='actionBtns'>
            {/* <button className='guid'>
              <img src='/assets/icons/flag.svg' />
              <span>Setup guide</span>
            </button>
            <button className='discard'>Discard</button>
            <button className='save'>Save</button> */}
          </div>
        </Header>
      ) : (
        <Header>
          <div>
            <img src='/assets/images/logo.png' className='logo' />
          </div>
          {/* <div className='search'>
            <IoSearchSharp className='searchIcon' />
            <input type='text' placeholder='search' />
          </div> */}
          <div className='userAvatarContainer'>
            {location?.pathname == "/drafts" && (
              <div className='guide'>
                <img src='/assets/icons/flag.svg' />
                <span>Setup guide</span>
              </div>
            )}
            <Dropdown overlay={menu}>
              <div className='userAvatarWrapper'>
                <Avatar className='userAvatar'>
                  {admin?.fullName?.charAt(0)}
                </Avatar>
                <span>{admin?.fullName}</span>
              </div>
            </Dropdown>
          </div>
        </Header>
      )}
    </>
  );
};

export default LayoutHeader;
