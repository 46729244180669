import auth from "./authSlice";
import giftCard from "./giftCardSlice";
import product from "./productSlice";
import amountOffProduct from "./amountOffProductSlice";
import amountOffOrder from "./amountOffOrderSlice";
import buyXgetY from "./buyXgetYSlice";
import freeShipping from "./freeShippingSlice";
import customer from "./customerSlice";
import order from "./orderSlice";

const rootReducer = {
  auth,
  order,
  giftCard,
  product,
  amountOffProduct,
  amountOffOrder,
  buyXgetY,
  freeShipping,
  customer,
};

export default rootReducer;
