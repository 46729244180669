import React, { useEffect } from "react";
import { Layout } from "antd";
import "./giftcard.scss";
import BreadCrumbs from "../../components/layout/BreadCrumbs";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllGiftCard } from "../../redux/giftCardSlice";

const { Content } = Layout;

const GiftCards = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const store = useSelector((state) => state?.giftCard);
  useEffect(() => {
    dispatch(getAllGiftCard());
  }, []);
  return (
    <>
      <div className='pageHeader'>
        <BreadCrumbs title='Gift cards' />
      </div>
      <Content className='giftCard'>
        <div className='mainContainer'>
          <div className='circle'></div>
          <h3>Digital gift cards</h3>
          <p>
            Gift cards can be sold as a product, or sent directly to <br />
            customers.
          </p>
          <div className='actionBtns'>
            <div className='addGift'>
              <button
                type='button'
                onClick={() => {
                  navigate("/add-gift-cards");
                }}>
                Add gift card product
              </button>
              <p>Let customers buy gift cards from Pictings.com</p>
            </div>
            <div className='issueGift'>
              <button
                type='button'
                onClick={() => {
                  navigate("/issue-gift-cards");
                }}>
                Issue gift card
              </button>
              <p>Send a gift card code directly to a customer</p>
            </div>
          </div>
        </div>
      </Content>
    </>
  );
};

export default GiftCards;
