import React, { useEffect } from "react";
import "./dashboard.scss";
import Loading from "../../components/custom/Loading";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllOrders,
  getCompletedOrders,
  getPendingOrders,
} from "../../redux/orderSlice";
import { getActiveProducts } from "../../redux/productSlice";
import { getAllCustomer } from "../../redux/customerSlice";

const Dashboard = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllOrders());
    dispatch(getCompletedOrders());
    dispatch(getPendingOrders());
    dispatch(getActiveProducts());
    dispatch(getAllCustomer());
  }, []);
  const store = useSelector((state) => state);
  const orders = store?.order?.allOrders;
  const completed = store?.order?.completedOrders;
  const pending = store?.order?.pendingOrders;
  const loading = store?.order?.loading;
  const active = store?.product?.activeProducts;
  const customers = store?.customer?.customers;

  // loading component
  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <div className='orderTiles'>
        <div className='orderTile'>
          <h2>Total Orders</h2>
          <p>{orders?.length}</p>
        </div>
        <div className='orderTile'>
          <h2>Completed Orders</h2>
          <p>{completed?.length}</p>
        </div>
        <div className='orderTile'>
          <h2>Pending Orders</h2>
          <p>{pending?.length}</p>
        </div>
      </div>

      <div className='productsTiles'>
        <div className='productTile'>
          <h2>Active Frames</h2>
          <p>{active?.length}</p>
        </div>

        <div className='productTile'>
          <h2>Customers</h2>
          <p>{customers?.length}</p>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
