import { Layout, Tabs } from "antd";
import React, { useState } from "react";
import BreadCrumbs from "../../components/layout/BreadCrumbs";
import { useNavigate } from "react-router-dom";
import All from "../../components/orders/tabs/All";
import "./order.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllOrders,
  getCompletedOrders,
  getPendingOrders,
  getCanceledOrders,
  getAllOrdersCanada,
} from "../../redux/orderSlice";
import OrdersFromCanada from "../../components/orders/tabs/OrdersFromCanada";
import Completed from "../../components/orders/tabs/Completed";
import Pending from "../../components/orders/tabs/Pending";
import Canceled from "../../components/orders/tabs/Canceled";

const { Content } = Layout;

const Orders = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useState(() => {
    dispatch(getAllOrders());
    dispatch(getCompletedOrders());
    dispatch(getPendingOrders());
    dispatch(getCanceledOrders());
    dispatch(getAllOrdersCanada());
  }, []);

  const store = useSelector((state) => state?.order);
  const orders = store?.allOrders;
  const completed = store?.completedOrders;
  const pending = store?.pendingOrders;
  const canceled = store?.canceledOrders;
  const orderFromCanada = store?.orderFromCanada;
  const loading = store?.loading;

  return (
    <>
      <div className='pageHeader'>
        <BreadCrumbs title='Orders' />
        <div className='pageHeaderAction'>
          {/* <button type='button' className='exportbtn'>
            Export
          </button> */}
          {/* <button type='button' className='addbtn'>
            Create Order
          </button> */}
        </div>
      </div>
      <Content className='drafts'>
        <Tabs
          defaultActiveKey='1'
          items={[
            {
              label: `All`,
              key: "1",
              children: <All allOrders={orders} loading={loading} />,
            },
            {
              label: `Completed`,
              key: "2",
              children: <Completed allOrders={completed} loading={loading} />,
            },
            {
              label: `Pending`,
              key: "3",
              children: <Pending allOrders={pending} loading={loading} />,
            },
            {
              label: `Canceled`,
              key: "4",
              children: <Canceled allOrders={canceled} loading={loading} />,
            },
            {
              label: `From Canada`,
              key: "5",
              children: (
                <OrdersFromCanada
                  allOrders={orderFromCanada}
                  loading={loading}
                />
              ),
            },
            // {
            //   label: `Paid Orders`,
            //   key: "6",
            //   children: <All allOrders={orders} loading={loading} />,
            // },
            // {
            //   label: `Customers from Canada`,
            //   key: "7",
            //   children: <All allOrders={orders} loading={loading} />,
            // },
          ]}
        />
      </Content>
      {/* <p className='infoText'>Lorem ipsum dolor sit amet, conse</p> */}
    </>
  );
};

export default Orders;
