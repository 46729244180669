import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiRequests } from "../services/ApiRequest";
import { toast } from "react-toastify";

//get all gift card
export const getAllGiftCard = createAsyncThunk(
  "giftCard/getAllGiftCard",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.getAllGiftCard();
      return response?.data?.giftCard;
    } catch (error) {
      console.log(error);
    }
  }
);

//get single gift card
export const getSingleGiftCard = createAsyncThunk(
  "giftCard/getSingleGiftCard",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.getSingleGiftCard(data);
      return response?.data;
    } catch (error) {
      console.log(error);
    }
  }
);

//issue gift card
export const issueGiftCard = createAsyncThunk(
  "giftCard/issueGiftCard",
  async (
    { data, reset, setShowDateField, navigate },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await ApiRequests.issueGiftCard(data);
      reset();
      setShowDateField(false);
      navigate("/gift-cards");
      toast.success("Gift Card issued successfully!", {
        position: "top-right",
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "light",
      });
    } catch (error) {
      toast.error(error?.response?.data?.error, {
        position: "top-right",
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "light",
      });
    }
  }
);

export const updateGiftCardImage = createAsyncThunk(
  "giftCard/updateGiftCardImage",
  async (
    { giftCardID, imageFormData, setFileList },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await ApiRequests.updateGiftCardImage(
        giftCardID,
        imageFormData
      );
      setFileList([]);
      dispatch(getSingleGiftCard(giftCardID));
    } catch (error) {
      console.log(error);
    }
  }
);

//add gift card
export const addGiftCard = createAsyncThunk(
  "giftCard/addGiftCard",
  async ({ formData, navigate }, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.addGiftCard(formData);
      navigate("/gift-cards");
      if (response.status === 200) {
        toast.success("Gift Card added successfully!", {
          position: "top-right",
          autoClose: 3000,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "light",
        });
      }
      if (response.status >= 400) {
        toast.error("Something Went Wrong! Try again.", {
          position: "top-right",
          autoClose: 3000,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "light",
        });
      }
    } catch (error) {
      toast.error("Something Went Wrong!", {
        position: "top-right",
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "light",
      });
    }
  }
);

// update gift card
export const updateGiftCard = createAsyncThunk(
  "giftCard/updateGiftCard",
  async (
    { giftCardID, formData, imageFormData, setFileList },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await ApiRequests.updateGiftCard(giftCardID, formData);
      if (imageFormData.has("image")) {
        dispatch(
          updateGiftCardImage({ giftCardID, imageFormData, setFileList })
        );
      } else {
        dispatch(getSingleGiftCard(giftCardID));
      }
      toast.success("Gift Card updated successfully!", {
        position: "top-right",
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "light",
      });
    } catch (error) {
      toast.error("Something Went Wrong!", {
        position: "top-right",
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "light",
      });
    }
  }
);

//delete giftcard
export const deleteGiftCard = createAsyncThunk(
  "giftCard/deleteGiftCard",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.deleteGiftCard(data);
      dispatch(getAllGiftCard());
      toast.success("GiftCard deleted Successfully!", {
        position: "top-right",
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "light",
      });
    } catch (error) {
      console.log(error);
    }
  }
);

// search giftcard
export const giftCardSearch = createAsyncThunk(
  "giftCard/giftCardSearch",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.giftCardSearch(data);
      return response?.data;
    } catch (error) {
      console.log(error);
    }
  }
);

const initialState = {
  giftCards: [],
  singleGiftCard: {},
  searchedGiftCards: [],
  loading: false,
  gifCardLoading: false,
};
const giftCardSlice = createSlice({
  name: "giftCard",
  initialState,
  reducers: {
    unSetGiftCard(state, action) {
      state.singleGiftCard = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllGiftCard.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllGiftCard.fulfilled, (state, action) => {
        state.giftCards = action.payload;
        state.loading = false;
      })

      .addCase(getSingleGiftCard.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSingleGiftCard.fulfilled, (state, action) => {
        state.singleGiftCard = action.payload;
        state.loading = false;
      })

      .addCase(issueGiftCard.pending, (state) => {
        state.loading = true;
      })
      .addCase(issueGiftCard.fulfilled, (state, action) => {
        state.loading = false;
      })

      .addCase(addGiftCard.pending, (state) => {
        state.loading = true;
      })
      .addCase(addGiftCard.fulfilled, (state, action) => {
        state.loading = false;
      })

      .addCase(updateGiftCard.pending, (state) => {
        state.gifCardLoading = true;
      })
      .addCase(updateGiftCard.fulfilled, (state, action) => {
        state.gifCardLoading = false;
      })

      .addCase(deleteGiftCard.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteGiftCard.fulfilled, (state, action) => {
        state.loading = false;
      })

      .addCase(giftCardSearch.pending, (state) => {
        state.loading = true;
      })
      .addCase(giftCardSearch.fulfilled, (state, action) => {
        state.searchedGiftCards = action.payload;
        state.loading = false;
      });
  },
});

export default giftCardSlice.reducer;
export const { unSetGiftCard } = giftCardSlice.actions;
