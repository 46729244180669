import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiRequests } from "../services/ApiRequest";
import { toast } from "react-toastify";

//get all free shipping discount
export const getAllFreeShipping = createAsyncThunk(
  "freeShipping/getAllFreeShipping",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.getAllFreeShipping();
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  }
);

// add free shipping discount
export const addFreeShipping = createAsyncThunk(
  "freeShipping/addFreeShipping",
  async ({ data, navigate }, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.addFreeShipping(data);
      navigate("/discount");
      toast.success("Discount is added successfully!", {
        position: "top-right",
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "light",
      });
    } catch (error) {
      toast.error(error?.response?.data?.msg, {
        position: "top-right",
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "light",
      });
    }
  }
);

// delete free shipping discount
export const deleteFreeShipping = createAsyncThunk(
  "freeShipping/deleteFreeShipping",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.deleteFreeShipping(data);
      console.log(response);
      toast.success("Amount Off Product discount deleted Successfully!", {
        position: "top-right",
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "light",
      });
    } catch (error) {
      console.log(error);
    }
  }
);

const initialState = {
  freeShipping: [],
  loading: false,
};
const freeShippingSlice = createSlice({
  name: "freeShipping",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllFreeShipping.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllFreeShipping.fulfilled, (state, action) => {
        state.freeShipping = action.payload;
        state.loading = false;
      })

      .addCase(addFreeShipping.pending, (state) => {
        state.loading = true;
      })
      .addCase(addFreeShipping.fulfilled, (state) => {
        state.loading = false;
      })

      .addCase(deleteFreeShipping.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteFreeShipping.fulfilled, (state) => {
        state.loading = false;
      });
  },
});

export default freeShippingSlice.reducer;
