import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiRequests } from "../services/ApiRequest";
import { toast } from "react-toastify";

//get all order
export const getAllOrders = createAsyncThunk(
  "order/getAllOrders",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.getAllOrders();
      return response?.data?.orders;
    } catch (error) {
      console.log(error);
    }
  }
);

//get All Orders Canada
export const getAllOrdersCanada = createAsyncThunk(
  "order/getAllOrdersCanada",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.getAllOrdersCanada();
      return response?.data?.ordersFromCanada;
    } catch (error) {
      console.log(error);
    }
  }
);

//get Pending Orders
export const getPendingOrders = createAsyncThunk(
  "order/getPendingOrders",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.getPendingOrders();
      return response?.data?.orders;
    } catch (error) {
      console.log(error);
    }
  }
);

//get Completed Orders
export const getCompletedOrders = createAsyncThunk(
  "order/getCompletedOrders",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.getCompletedOrders();
      return response?.data?.orders;
    } catch (error) {
      console.log(error);
    }
  }
);

//get Canceled Orders
export const getCanceledOrders = createAsyncThunk(
  "order/getCanceledOrders",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.getCanceledOrders();
      return response?.data?.orders;
    } catch (error) {
      console.log(error);
    }
  }
);

//get single order
export const getSingleOrder = createAsyncThunk(
  "order/getSingleOrder",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.getSingleOrder(data);
      return response?.data?.order;
    } catch (error) {
      console.log(error);
    }
  }
);

//update order
export const updateOrder = createAsyncThunk(
  "order/updateOrder",
  async ({ orderID, data }, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.updateOrder(orderID, data);

      dispatch(getSingleOrder(orderID));
      toast.success("order status has been updated!", {
        position: "top-right",
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "light",
      });
    } catch (error) {
      console.log(error);
    }
  }
);

const initialState = {
  allOrders: [],
  orderFromCanada: [],
  completedOrders: [],
  pendingOrders: [],
  canceledOrders: [],
  singleOrder: {},
  loading: false,
};
const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    unSetGiftCard(state, action) {
      state.singleGiftCard = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllOrders.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllOrders.fulfilled, (state, action) => {
        state.allOrders = action.payload;
        state.loading = false;
      })

      .addCase(getSingleOrder.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSingleOrder.fulfilled, (state, action) => {
        state.singleOrder = action.payload;
        state.loading = false;
      })

      .addCase(getCompletedOrders.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCompletedOrders.fulfilled, (state, action) => {
        state.completedOrders = action.payload;
        state.loading = false;
      })

      .addCase(getPendingOrders.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPendingOrders.fulfilled, (state, action) => {
        state.pendingOrders = action.payload;
        state.loading = false;
      })

      .addCase(getCanceledOrders.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCanceledOrders.fulfilled, (state, action) => {
        state.canceledOrders = action.payload;
        state.loading = false;
      })

      .addCase(getAllOrdersCanada.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllOrdersCanada.fulfilled, (state, action) => {
        state.orderFromCanada = action.payload;
        state.loading = false;
      });
  },
});

export default orderSlice.reducer;
export const { unSetGiftCard } = orderSlice.actions;
