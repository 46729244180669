import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiRequests } from "../services/ApiRequest";
import { toast } from "react-toastify";

//get all products
export const getAllProducts = createAsyncThunk(
  "products/getAllProducts",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.getAllProducts();
      return response?.data?.frames;
    } catch (error) {
      console.log(error);
    }
  }
);

//Active products
export const getActiveProducts = createAsyncThunk(
  "products/getActiveProducts",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.getActiveProducts();
      return response?.data?.frames;
    } catch (error) {
      console.log(error);
    }
  }
);

//closed products
export const getClosedProducts = createAsyncThunk(
  "products/getClosedProducts",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.getClosedProducts();
      return response?.data?.frames;
    } catch (error) {
      console.log(error);
    }
  }
);

//archived products
export const getArchivedProducts = createAsyncThunk(
  "products/getArchivedProducts",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.getArchivedProducts();
      return response?.data?.frames;
    } catch (error) {
      console.log(error);
    }
  }
);

//get all products
export const getSingleProducts = createAsyncThunk(
  "products/getSingleProducts",
  async (producID, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.getSingleProducts(producID);
      return response?.data?.frame;
    } catch (error) {
      console.log(error);
    }
  }
);

// search product
export const productSearch = createAsyncThunk(
  "products/productSearch",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.productSearch(data);
      return response?.data;
    } catch (error) {
      console.log(error);
    }
  }
);

// add product image
export const updateProductImage = createAsyncThunk(
  "products/updateProductImage",
  async ({ productID, formData }, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.updateProductImage(
        productID,
        formData
      );
      dispatch(getAllProducts());
    } catch (error) {
      console.log(error);
    }
  }
);

export const updateProductAndImage = createAsyncThunk(
  "products/updateProductAndImage",
  async (
    { productID, formData, setFileList },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await ApiRequests.updateProductImage(
        productID,
        formData
      );
      setFileList({});
      dispatch(getSingleProducts(productID));
    } catch (error) {
      console.log(error);
    }
  }
);

// add product
export const addProduct = createAsyncThunk(
  "products/addProduct",
  async ({ data, formData, navigate }, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.addProduct(data);
      if (response.status === 200) {
        const productID = response?.data?._id;
        dispatch(updateProductImage({ productID, formData }));
        toast.success("New Product has been Created!", {
          position: "top-right",
          autoClose: 3000,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "light",
        });

        navigate("/products");
      }
    } catch (error) {
      toast.error(error?.response?.data, {
        position: "top-right",
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "light",
      });
    }
  }
);

// Update product
export const updateProduct = createAsyncThunk(
  "products/updateProduct",
  async (
    { productID, formData, data, setFileList },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await ApiRequests.updateProduct(productID, data);
      if (formData.has("image")) {
        dispatch(updateProductAndImage({ productID, formData, setFileList }));
      } else {
        dispatch(getSingleProducts(productID));
      }
      toast.success("Product updated Successfully!", {
        position: "top-right",
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "light",
      });
    } catch (error) {
      console.log(error);
      toast.error("Something Went Wrong!", {
        position: "top-right",
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "light",
      });
    }
  }
);

// delete product
export const deleteProduct = createAsyncThunk(
  "products/deleteProduct",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.deleteProduct(data);
      dispatch(getAllProducts());
      toast.success("Product deleted Successfully!", {
        position: "top-right",
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "light",
      });
    } catch (error) {
      console.log(error);
    }
  }
);

const initialState = {
  products: [],
  activeProducts: [],
  closedProducts: [],
  archivedProducts: [],
  searchedProducts: [],
  singleProduct: {},
  loading: false,
  productLoading: false,
};
const productSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    unSetSingleProduct(state, action) {
      state.singleProduct = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllProducts.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllProducts.fulfilled, (state, action) => {
        state.products = action.payload;
        state.loading = false;
      })

      .addCase(getActiveProducts.pending, (state) => {
        state.loading = true;
      })
      .addCase(getActiveProducts.fulfilled, (state, action) => {
        state.activeProducts = action.payload;
        state.loading = false;
      })

      .addCase(getClosedProducts.pending, (state) => {
        state.loading = true;
      })
      .addCase(getClosedProducts.fulfilled, (state, action) => {
        state.closedProducts = action.payload;
        state.loading = false;
      })

      .addCase(getArchivedProducts.pending, (state) => {
        state.loading = true;
      })
      .addCase(getArchivedProducts.fulfilled, (state, action) => {
        state.archivedProducts = action.payload;
        state.loading = false;
      })

      .addCase(getSingleProducts.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSingleProducts.fulfilled, (state, action) => {
        state.singleProduct = action.payload;
        state.loading = false;
      })

      .addCase(productSearch.pending, (state) => {
        state.loading = true;
      })
      .addCase(productSearch.fulfilled, (state, action) => {
        state.searchedProducts = action.payload;
        state.loading = false;
      })

      .addCase(addProduct.pending, (state) => {
        state.loading = true;
      })
      .addCase(addProduct.fulfilled, (state, action) => {
        state.loading = false;
      })

      .addCase(updateProduct.pending, (state) => {
        state.productLoading = true;
      })
      .addCase(updateProduct.fulfilled, (state, action) => {
        state.productLoading = false;
      });
  },
});

export default productSlice.reducer;
export const { unSetSingleProduct } = productSlice.actions;
