import React from "react";
import { LoadingOutlined } from "@ant-design/icons";

const Loading = () => {
  return (
    <div className='loadingBox'>
      <LoadingOutlined className='loadingIcon' />
    </div>
  );
};

export default Loading;
