import React from "react";
import { Layout } from "antd";
import SidebarMenu from "./SidebarMenu";

const { Sider } = Layout;

const LayoutSidebar = () => {
  return (
    <Sider width={200} className='site-layout-background'>
      <SidebarMenu />
    </Sider>
  );
};

export default LayoutSidebar;
