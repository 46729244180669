import { Layout, Radio, Checkbox, DatePicker, TimePicker } from "antd";
import React, { useState } from "react";
import BreadCrumbs from "../../components/layout/BreadCrumbs";
import { BiLeftArrowAlt, BiDollar } from "react-icons/bi";
import { FaPercent } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import "./discount.scss";
import { useForm, Controller } from "react-hook-form";
import { generateCode } from "../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import { addFreeShipping } from "../../redux/freeShippingSlice";
import { FormError } from "../../components/custom/Custom";

const { Content } = Layout;

const FreeShipping = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [minimumPurchaseField, setMinimumPurchaseField] = useState("noMinimum");
  const [customerField, setcustomerField] = useState("all");
  const [endDateField, setEndDateField] = useState(false);
  const [shippingRateField, setShippingRateField] = useState(false);
  const [limitUses, setLimitUses] = useState(false);
  const [limitCustomer, setLimitCustomer] = useState(false);

  const store = useSelector((state) => state?.freeShipping);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors },
  } = useForm();

  const onFormSubmit = (values) => {
    const data = {
      discountCode: values.code,
      shippingRates: shippingRateField,
      minimumPurchaseRequirements: {
        NO_MINIMUM_REQUIREMENTS:
          minimumPurchaseField === "noMinimum" ? true : false,
        MINIMUM_PURCHASE_AMOUNT: {
          flag: minimumPurchaseField === "minimumAmount" ? true : false,
          minimumPurchaseAmount:
            minimumPurchaseField === "minimumAmount"
              ? values.minimumAmount
              : "0",
        },
        MINIMUM_QUANTITY_OF_ITEMS: {
          flag: minimumPurchaseField === "minimumQty" ? true : false,
          minimumQuantityOfItems:
            minimumPurchaseField === "minimumQty" ? values.minimumQty : "0",
        },
      },
      maximumDiscountUses: {
        LIMIT_NUMBER_OF_TIMES: {
          flag: limitUses,
          data: limitUses ? values.usageLimit : 0,
        },
        LIMIT_TO_ONE_USE_PER_CUSTOMER: {
          flag: limitCustomer,
        },
      },
      activeDates: {
        startDate: values.startDate,
        endDate: values.endDate,
        startTime: values.startTime,
        endTime: "00:00:00",
      },
      active: true,
    };
    dispatch(addFreeShipping({ data, navigate }));
  };
  return (
    <>
      <div className='freeShippingContainer'>
        <div className='addFreeShipping'>
          <div className='pageHeader'>
            <BiLeftArrowAlt
              className='backBtn'
              onClick={() => {
                navigate("/discount");
              }}
            />
            <BreadCrumbs title='Create shipping discount' />
          </div>
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <Content className='freeShippingContent'>
              {/* right block */}
              <div className='rightBlock'>
                <div className='ammount'>
                  <div className='header'>
                    <h3>Free Shipping</h3>
                    <p>Shipping discount</p>
                  </div>

                  <div className='body'>
                    <div className='code'>
                      <div className='discountCode'>
                        <label htmlFor=''>Discount code</label>
                        <div className='discountCodeFiled'>
                          <input
                            type='text'
                            {...register("code", {
                              required: "code is required",
                            })}
                          />
                          <img src='/assets/icons/addressCard.svg' />
                        </div>
                        {errors?.code?.message ? (
                          <FormError message={errors?.code?.message} />
                        ) : (
                          <p>Customers must enter this code at checkout.</p>
                        )}
                      </div>
                      <button
                        type='button'
                        onClick={() => setValue("code", generateCode(9))}>
                        Generate
                      </button>
                    </div>
                  </div>
                </div>

                <div className='country'>
                  <div className='shippingRates'>
                    <h3>Shipping Rates</h3>
                    <Checkbox
                      value={shippingRateField}
                      onChange={(e) => setShippingRateField(e.target.checked)}>
                      Exclude shipping rates over a certain amount
                    </Checkbox>
                  </div>
                </div>

                <div className='minimumRequired'>
                  <h3>Minimum purchase requirements</h3>
                  <Radio.Group
                    className='radioBtn'
                    value={minimumPurchaseField}
                    onChange={(e) => {
                      setMinimumPurchaseField(e.target.value);
                    }}>
                    <Radio value='noMinimum'>No minimum requirements</Radio>

                    <Radio value='minimumAmount'>
                      Minimum purchase amount ($)
                    </Radio>
                    {minimumPurchaseField === "minimumAmount" && (
                      <>
                        <div className='discountedValueFiled'>
                          <BiDollar className='icon' />
                          <input
                            type='number'
                            min='0'
                            {...register("minimumAmount", {
                              required: "minimum amount is required",
                            })}
                          />
                        </div>
                        <FormError message={errors?.minimumAmount?.message} />
                      </>
                    )}
                    <Radio value='minimumQty'>Minimum quantity of items</Radio>
                    {minimumPurchaseField === "minimumQty" && (
                      <>
                        <div className='discountedValueFiled'>
                          <input
                            type='number'
                            min='0'
                            {...register("minimumQty", {
                              required: "minimum quantity is required",
                            })}
                          />
                        </div>
                        <FormError message={errors?.minimumQty?.message} />
                      </>
                    )}
                  </Radio.Group>
                </div>

                <div className='discountUsage'>
                  <h3>Maximum discount uses</h3>
                  <Checkbox.Group className='checkboxBtn'>
                    <Checkbox
                      value='limitUses'
                      onChange={(e) => setLimitUses(e.target.checked)}>
                      Limit number of times this discount can be used in total
                    </Checkbox>
                    {limitUses && (
                      <>
                        <div className='discountedValueFiled'>
                          <input
                            type='number'
                            min='0'
                            name='usageLimit'
                            {...register("usageLimit", {
                              required: "usage limit is required",
                            })}
                          />
                        </div>
                        <FormError message={errors?.usageLimit?.message} />
                      </>
                    )}

                    <Checkbox
                      value='limitCustomer'
                      onChange={(e) => setLimitCustomer(e.target.checked)}>
                      Limit to one use per customer
                    </Checkbox>
                  </Checkbox.Group>
                </div>

                <div className='activeDates'>
                  <h3>Active dates</h3>
                  <div className='wrapper'>
                    <div className='dateFiledWrapper'>
                      <label>Start date</label>
                      <div className='filed'>
                        <img src='/assets/icons/calendar.svg' />
                        <Controller
                          control={control}
                          name='startDate'
                          rules={{ required: "start date is required" }}
                          render={({ field }) => (
                            <DatePicker
                              onChange={(_, dateString) =>
                                field.onChange(dateString)
                              }
                              selected={field.value}
                              bordered={false}
                              suffixIcon={false}
                            />
                          )}
                        />
                      </div>
                      <FormError message={errors?.startDate?.message} />
                    </div>

                    <div className='timeFiledWrapper'>
                      <label>Start time (CDT)</label>
                      <div className='filed'>
                        <img src='/assets/icons/clock.svg' />
                        <Controller
                          control={control}
                          name='startTime'
                          rules={{ required: "start time is required" }}
                          render={({ field }) => (
                            <TimePicker
                              onChange={(_, timeString) =>
                                field.onChange(timeString)
                              }
                              selected={field.value}
                              bordered={false}
                              suffixIcon={false}
                            />
                          )}
                        />
                      </div>
                      <FormError message={errors?.startTime?.message} />
                    </div>
                  </div>
                  <Checkbox
                    className='setEndDate'
                    onChange={(e) => setEndDateField(e.target.checked)}>
                    Set end date
                  </Checkbox>
                  {endDateField && (
                    <div className='dateFiledWrapper'>
                      <label>End date</label>
                      <div className='filed'>
                        <img src='/assets/icons/calendar.svg' />
                        <Controller
                          control={control}
                          name='endDate'
                          rules={{ required: "end date is required" }}
                          render={({ field }) => (
                            <DatePicker
                              onChange={(_, dateString) =>
                                field.onChange(dateString)
                              }
                              selected={field.value}
                              bordered={false}
                              suffixIcon={false}
                            />
                          )}
                        />
                      </div>
                      <FormError message={errors?.endDate?.message} />
                    </div>
                  )}
                </div>
              </div>

              {/* left block */}
              <div className='leftBlock'>
                <div className='summary'>
                  <div className='header'>
                    <h3>Summary</h3>
                  </div>
                  <div className='body'>
                    <p>No discount code yet.</p>
                    <div className='list'>
                      <h3>Type and method</h3>
                      <ul>
                        <li>Amount off products</li>
                        <li>Code</li>
                      </ul>
                      <h3>Details</h3>
                    </div>
                  </div>

                  <div className='footer'>
                    <h3>Performance</h3>
                    <p>Discount is not active yet.</p>
                  </div>
                </div>
              </div>
            </Content>
            <div className='pageFooter'>
              <button
                type='button'
                className='discard'
                onClick={() => {
                  navigate("/discount");
                }}>
                Discard
              </button>
              <button type='submit' className='save'>
                {store?.loading ? "Loading..." : "Save discount"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default FreeShipping;
