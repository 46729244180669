import React from "react";
import "../../styles/custom.scss";

export const Card = ({ styleClass, children }) => {
  return <div className={`card ${styleClass}`}>{children}</div>;
};

export const FormError = ({ message }) => {
  return <p className='formErrorMessage'>{message}</p>;
};
